import React, { useState } from 'react'
import { FaArrowUp } from 'react-icons/fa6'
import './styles/Hero.css'
import { Modal } from 'react-bootstrap'
import { MdPhoneInTalk } from "react-icons/md";
import { calendly } from '../../assets/images';
import { IoClose } from "react-icons/io5";

const Hero = () => {

    const [showConsultantModal, setShowConsultantModal] = useState(false);

    return (
        <div className='hero-container'>
            <div className="hero-content">
                <p className='hero-heading'>
                    <span>Empower</span> Your Nightlife Business with Frizda
                </p>

                <p className='hero-tagline'>
                    Discover innovative tools to transform your
                    nightlife business. Elevate your venues and
                    events with Frizda's cutting-edge solutions.
                </p>

                <div className="hero-btn-container">
                    <button type='button' className="hero-btn">
                        Get Started <FaArrowUp style={{ transform: 'rotate(45deg)' }} />
                    </button>
                    <button type='button' className="call-consultant-btn" onClick={() => setShowConsultantModal(true)}>
                        Call a Consultant
                    </button>
                </div>
            </div>
            <div className="hero-bg">
            </div>

            <ConsultantModal showConsultantModal={showConsultantModal} setShowConsultantModal={setShowConsultantModal} />
        </div>
    )
}

export default Hero


const ConsultantModal = ({ showConsultantModal, setShowConsultantModal }) => {
    return (
        <Modal
            show={showConsultantModal}
            onHide={setShowConsultantModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='consultant-modal'
        >
            <Modal.Body className="consultant-modal-body">
                <div className="consultant-modal-header">
                    <h5>Schedule a Consultation</h5>
                    <p>Get ready with your questions to make the most of your session</p>
                </div>
                
                <div className="consultant-card-item">
                    <div className="consultant-item-image">
                        <MdPhoneInTalk size={50} color='#7A38FE' />
                    </div>
                    <div className="consultant-item-description">
                        <p className='header' style={{ color: '#7A38FE' }}>Connect over a Free Call with Consultant</p>
                        <p className='description'>This call will connect you to a consultant in Spain</p>
                    </div>
                </div>

                <div className='w-100 text-center' style={{ color: '#4A4A4A' }}>OR</div>

                <div className="consultant-card-item">
                    <div className="consultant-item-image">
                        <img src={calendly} alt="Calendly" className='w-100 h-100 object-fit-contain' />
                    </div>
                    <div className="consultant-item-description">
                        <p className='header' style={{ color: '#006BFF' }}>Schedule a Meet on Calendly</p>
                        <p className='description'>Book a Calendly meeting at your convenience</p>
                    </div>
                </div>

                <div className="position-absolute d-flex justify-content-center align-items-center cursor-pointer" style={{ top: 20, right: 20 }} onClick={() => setShowConsultantModal(false)}>
                    <IoClose size={25} color='#4A4A4A' />
                </div>
            </Modal.Body>
        </Modal >
    );
}
import React, { useContext } from 'react'
import './styles/FAQ.css'
import { Accordion, AccordionContext, Card, useAccordionButton } from 'react-bootstrap'
import { FaPlus, FaMinus } from 'react-icons/fa6'

const CustomToggleBtn = ({ children, eventKey, callback }) => {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey),
    );

    const isCurrentEventKey = activeEventKey === eventKey;

    return (
        <div className="faq-accordion-header" onClick={decoratedOnClick}>
            {isCurrentEventKey ? <FaMinus size={20} /> : <FaPlus size={20} />} {children}
        </div>
    );
}

const FAQ = () => {

    const question = [
        {
            id: 1,
            question: "What types of businesses can benefit from using Frizda?",
            answer: "Frizda is designed for a wide range of nightlife businesses, including clubs, bars, lounges, and event venues. Whether you run a small local bar or manage a large nightclub, Frizda's features can be tailored to meet your specific needs and help grow your business."
        },
        {
            id: 2,
            question: "What types of businesses can benefit from using Frizda?",
            answer: "Frizda is designed for a wide range of nightlife businesses, including clubs, bars, lounges, and event venues. Whether you run a small local bar or manage a large nightclub, Frizda's features can be tailored to meet your specific needs and help grow your business."
        },
        {
            id: 3,
            question: "How can Frizda's Affiliation System help grow my business?",
            answer: "Frizda is designed for a wide range of nightlife businesses, including clubs, bars, lounges, and event venues. Whether you run a small local bar or manage a large nightclub, Frizda's features can be tailored to meet your specific needs and help grow your business."
        },
        {
            id: 4,
            question: "How does Frizda's ticket selling feature work?",
            answer: "Frizda is designed for a wide range of nightlife businesses, including clubs, bars, lounges, and event venues. Whether you run a small local bar or manage a large nightclub, Frizda's features can be tailored to meet your specific needs and help grow your business."
        },
        {
            id: 5,
            question: "Can Frizda help me understand my customers better?",
            answer: "Frizda is designed for a wide range of nightlife businesses, including clubs, bars, lounges, and event venues. Whether you run a small local bar or manage a large nightclub, Frizda's features can be tailored to meet your specific needs and help grow your business."
        },
        {
            id: 6,
            question: "Is Frizda difficult to set up and use?",
            answer: "Frizda is designed for a wide range of nightlife businesses, including clubs, bars, lounges, and event venues. Whether you run a small local bar or manage a large nightclub, Frizda's features can be tailored to meet your specific needs and help grow your business."
        },
    ]

    return (
        <div className="faq-section">
            <div className="faq-header">
                <p className='d-none d-md-block'>Frequently Asked Questions (FAQs)</p>
                <p className='d-block d-md-none'>FAQs</p>
            </div>
            <div className="faq-accordion-wrapper">
                <Accordion defaultActiveKey="0">
                    {
                        question.slice(0, 3).map((item) => {
                            return (
                                <Card key={item.id} className='mb-3'>
                                    <Card.Header>
                                        <CustomToggleBtn eventKey={item.id}>{item.question}</CustomToggleBtn>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={item.id}>
                                        <Card.Body>{item.answer}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })
                    }
                </Accordion>
                <Accordion defaultActiveKey="0">
                    {
                        question.slice(3, 6).map((item) => {
                            return (
                                <Card key={item.id} className='mb-3'>
                                    <Card.Header>
                                        <CustomToggleBtn eventKey={item.id}>{item.question}</CustomToggleBtn>
                                    </Card.Header>
                                    <Accordion.Collapse eventKey={item.id}>
                                        <Card.Body>{item.answer}</Card.Body>
                                    </Accordion.Collapse>
                                </Card>
                            )
                        })
                    }
                </Accordion>
            </div>
        </div>
    )
}

export default FAQ
import React from 'react';
import '../../styling/styles/home-page-styling/news.css';  // ← CSS file for this React component

function News(props) {
    return (
        <div className='container-fluid'>
            <div id='news-section' className='grid gap-5'>
                <div className='col-lg-6 col-md-12'>
                    <div className='news-container'>
                        <img alt='new1' />
                    </div>
                    <div>
                        <h4 className='mt-5'>Eiusmod do ullamco qui ad laborum eiusmod ut nisi. Fugiat cupidatat incididunt excepteur tempor et.</h4>
                    </div>
                </div>

                <div className='col-lg-6 col-md-12'>
                    <div className='news-container'>
                        <img alt='new2' />
                    </div>
                    <div className='mt-5'>
                        <h4>Occaecat tempor sit deserunt minim enim irure cillum duis nostrud mollit minim ex. Non eiusmod veniam et duis ipsum cupidatat ea labore eiusmod sunt qui ad.</h4>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default News;
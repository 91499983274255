import React from "react";

import "../location/location.css"

import map from "../../styling/imgs/MAP.png"
function Location() {
  return (
    <div>
      <div className="service-location-content-div">
        <div className="service-location-left-div">
          <h1 className="service-location-left-side-heading">Discover the <span className="service-location-left-side-heading-span">Nightlife</span>  Around You!</h1>
          <h1 className="service-location-left-side-para">Get Exclusive Benefits with Frizda</h1>
        </div>
        <div className="service-location-right-div">
          {/* <img className="service-location-right-image" src={map} alt="mao" />1 */}
          <iframe
            // width='100%'
            // height='400px'
            className="service-location-right-image"
            src="https://api.mapbox.com/styles/v1/frizda/clor7g9aq00nt01o4c22gfrxs.html?title=false&access_token=pk.eyJ1IjoiZnJpemRhIiwiYSI6ImNsb3ExN3cyeDBnMWMycm12ZWxhOXQ0ejEifQ.J_7FV9WYgfgXHCz5M_8U4g&zoomwheel=false#11/48.138/11.575"
            title="Navigation"
            style={{ border: 'none' }}
          />
        </div>
      </div>
    </div>
  );
}

export default Location;

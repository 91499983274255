import React from 'react'

const FeatureCard = ({ imgUrl, name, description }) => {
    return (
        <div className="feature-card">
            <div className="feature-card-img-wrapper">
                <img src={imgUrl} alt={name} style={{ width: "100%", height: "100%", objectFit: "fill" }} />
            </div>
            <p className='feature-name'>{name}</p>
            <div className="feature-description">
                <p>{description}</p>
            </div>
        </div>
    )
}

export default FeatureCard
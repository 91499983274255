import { motion } from 'framer-motion';
import React, { useEffect, useRef, useState } from 'react';
import { FaPlus, FaTimes } from 'react-icons/fa';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import '../dashboard.css';

const diningModesEnum = {
    food: 'Food',
    drinks: 'Drinks',
};

const DrinksAndDining = ({
    nextSlide,
    prevSlide,
    diningModes,
    setDiningModes,
    diningOptions,
    setMenuImage,
    menuImage,
    delta,
    register,
    errors,
    setValue,
    isEditMode,
    editModeSaveHandler,
    isMediumOrSmallScreen
}) => {

    const [sellsFood, setSellsFood] = useState(false);
    const [sellsDrinks, setSellsDrinks] = useState(false);

    const toggleDiningMode = (id) => {
        setDiningModes(prevModes =>
            prevModes.includes(id) ? prevModes.filter(mode => mode !== id) : [...prevModes, id]
        );

        if (id === 'food') {
            setSellsFood(prev => !prev);
        } else if (id === 'drinks') {
            setSellsDrinks(prev => !prev);
        }
    };

    const fileInputRef = useRef(null);

    const openImagePicker = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setMenuImage(imageUrl);
            setValue('drinkSale.menu', imageUrl);
        }
    };

    const removeImage = () => {
        setMenuImage(null)
        setValue('drinkSale.menu', null);
    }

    useEffect(() => {
        setValue('drinkSale.sellsFood', sellsFood);
    }, [sellsFood]);

    useEffect(() => {
        setValue('drinkSale.sellsDrinks', sellsDrinks);
    }, [sellsDrinks]);

    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`h-100 d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
            style={{ animation: isEditMode ? 'none' : '' }}
        >
            <div className='d-flex flex-fill flex-column '>
                {
                    !isEditMode &&
                    <div className='d-flex  justify-content-center mb-5'>
                        <span className='title text-black fs-4 '>Drinks & Dining</span>
                    </div>
                }

                <div className='d-flex flex-row justify-content-around w-100 h-50'>
                    {diningOptions.map(option => (
                        <div
                            key={option.id}
                            className={`payment-mode-type-option fw-semibold ${diningModes.includes(option.id) ? 'selected-payment-mode' : ''}`}
                            style={{ width: '47%', height: 60, cursor: 'pointer', fontSize: '16px' }}
                            onClick={() => { toggleDiningMode(option.id); }}
                        >
                            {option.icon(option.label)}
                            <span className={`text-secondary ms-4 ${diningModes.includes(option.id) ? 'selected-payment-mode-text' : ''}`}>{option.label}
                            </span>
                        </div>
                    ))}
                </div>

                <div className='d-flex flex-column w-100 h-75' style={{ minHeight: isEditMode ? '200px' : '' }}>
                    <span className='text-black fs-6 fw-semibold mb-3 '>
                        Upload Menu
                        &nbsp;<span className='text-desc mt-2' style={{ fontFamily: 'DM Sans', color: '#555555' }}>(*Optional)</span>
                    </span>

                    <div className='d-flex'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex add-btn ' >
                                <PrimaryButton type="button" title={'Add'} icon={<FaPlus size={10} />} height={30} onClick={openImagePicker} />
                            </div>
                        </div>
                    </div>
                    {menuImage && <div className='position-relative d-flex mt-3 image-wrapper-menu border border-2 rounded border-white  justify-content-center position-relative'>
                        <div style={{ height: '120px', width: '250px', overflow: 'hidden', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <iframe src={menuImage} style={{ height: '120px', width: '250px' }} />
                        </div>
                        <button className='remove-image-button' onClick={removeImage}>
                            <FaTimes size={10} color='#000' />
                        </button>
                    </div>}
                    {/* Hidden File Input */}
                    <input
                        {...register('drinkSale.menu')}
                        type="file"
                        accept='.pdf'
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                </div>
            </div>
            <div className="">
                {errors.drinkSale?.menu && <span className='input-error'>{errors.drinkSale.menu.message}</span>}
            </div>
            {
                !isMediumOrSmallScreen
                    ?
                    isEditMode ?
                        <div className="mt-2">
                            <button onClick={() => editModeSaveHandler(8)} className='edit-mode-save-btn'>Save</button>
                        </div>
                        :
                        <div className='d-flex flex-row justify-content-between'>
                            <SecondaryButton title={'Previous'} onClick={prevSlide} />
                            <PrimaryButton type='submit' title={'Next'} onClick={nextSlide} />
                        </div>
                    :
                    <></>
            }
        </motion.div>
    )
}

export default DrinksAndDining
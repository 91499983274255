import React from 'react'
import { Autoplay } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import {
    affilicationSystem,
    clientDataInsights,
    frizdaAds,
    frizdaSmartMap,
    monetoryStatistics,
    ticketSelling
} from '../../assets/images'
import FeatureCard from './FeatureCard'
import './styles/Features.css'

const Features = () => {

    const FEATURES = [
        {
            id: 1,
            imgUrl: affilicationSystem,
            name: 'Affiliation System',
            description: 'Boost your venue\'s popularity by connecting with influencers and promoters. Track referrals, manage commissions, and reward top performers automatically.',
        },
        {
            id: 2,
            imgUrl: monetoryStatistics,
            name: 'Monetory Statistics',
            description: 'Get real-time insights into revenue, ticket sales, and bar tabs. Visualize trends and make data-driven decisions to maximize profits.',
        },
        {
            id: 3,
            imgUrl: clientDataInsights,
            name: 'Client Data Insights',
            description: 'Collect and analyze information on patron preferences, visit frequency, and spending habits. Tailor offerings and create targeted marketing campaigns.',
        },
        {
            id: 4,
            imgUrl: ticketSelling,
            name: 'Seamless Ticket Selling',
            description: 'Simplify ticketing with easy event management, real-time sales tracking, and mobile-friendly tickets. Offer a smooth purchasing experience.'
        },
        {
            id: 5,
            imgUrl: frizdaAds,
            name: 'Frizda Ads',
            description: 'Increase visibility with targeted promotions within the Frizda ecosystem. Create eye-catching ads and track performance'
        },
        {
            id: 6,
            imgUrl: frizdaSmartMap,
            name: 'Frizda SmartMap',
            description: 'Use markers with different colors and intensity on a map to highlight key information about your venue\'s location and events.'
        }
    ]
    return (
        <div className='features-section'>
            <div className="feature-header">
                <p>Features that help you scale your Nightspots Business</p>
            </div>
            <div className="feature-card-wrapper">
                {
                    FEATURES.map((feature) => (
                        <FeatureCard key={feature.id} {...feature} />
                    ))
                }
            </div>
            <div className="d-sm-none">
                <Swiper
                    centeredSlides={true}
                    loop={true}
                    autoplay={{ delay: 2000, disableOnInteraction: false }}
                    className="m-0 feature-card-swiper"
                    modules={[Autoplay]}
                >
                    {
                        FEATURES.map((feature) => (
                            <SwiperSlide key={feature.id}>
                                <FeatureCard {...feature} />
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
            </div>
        </div>
    )
}

export default Features
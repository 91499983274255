import React from 'react'
import {
    step1,
    step2,
    step3,
    step4,
    stepCounts,
    responsiveStepCounts
} from '../../assets/images'
import './styles/FrizdaHelp.css'

const FrizdaHelp = () => {

    const STEPS = [
        {
            id: 1,
            imgUrl: step1,
            name: 'Register Your Business',
            description: '"Register your nightclub, bar or event venue to get started."'
        },
        {
            id: 2,
            imgUrl: step2,
            name: 'Venue & Branding',
            description: 'Share your venue\'s details & upload logos and photos.'
        },
        {
            id: 3,
            imgUrl: step3,
            name: 'Engage with Customers',
            description: 'Promote events, send offers, and connect with customers.'
        },
        {
            id: 4,
            imgUrl: step4,
            name: 'Manage Operations',
            description: 'Monitor performance, sales, and operations with detailed analytics.'
        },
    ]

    return (
        <div className='help-section'>
            <div className="help-header">
                <p>How <span>Frizda</span> Works</p>
            </div>
            <div className="step-wrapper">
                <div className="step-count-wrapper">
                    <img src={stepCounts} alt="Steps" className='step-count-img' />
                    <img src={responsiveStepCounts} alt="Steps" className='responsive-step-count-img' />
                </div>
                <div className="steps-wrapper">
                    {
                        STEPS.map(({ id, imgUrl, name, description }) => (
                            <div className="step-card" key={id}>
                                <p className='step-name'>{name}</p>
                                <div className="step-description">
                                    <p>{description}</p>
                                </div>
                                <div className="step-card-img-wrapper">
                                    <img src={imgUrl} alt={name} style={{ width: "100%", height: "100%", objectFit: "fill" }} />
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>
    )
}

export default FrizdaHelp
import { yupResolver } from '@hookform/resolvers/yup';
import moment from 'moment/moment';
import React from 'react';
import { Accordion, Card, Col, Row } from 'react-bootstrap';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { FaRegTimesCircle } from 'react-icons/fa';
import * as yup from "yup";
import { ticketDanceClubOption, ticketDiscoBallOption, ticketNightClubOption } from '../../../assets/images';
import PrimaryButton from '../components/PrimaryButton';
import TicketPreview from './TicketPreview';


const ticketSchema = yup.object().shape({
    name: yup.string().required('*Name is required'),
    description: yup.string().required('*Add a description'),
    benefits: yup.array()
        .of(yup.string().required('*Enter benefit'))
        .max(11, '*You can add a maximum of 12 benefits')
        .required('*Benefits are required'),
    eventDate: yup.number().required('*Event date is required'),
    theme: yup.string().required('*Theme is required'),
    bgImage: yup.string().required('*Background image is required'),
    price: yup.number().required('*Enter price').test(
        'is-required-if-not-free',
        '*Enter price',
        function (value) {
            const { free } = this.parent;
            if (!free && (!value)) {
                return false;
            }
            return true;
        }
    ),
    limit: yup.number().required('*Enter limit').test(
        'is-required-if-not-free',
        '*Enter limit',
        function (value) {
            const { free } = this.parent;
            if (!free && (!value)) {
                return false;
            }
            return true;
        }
    ),
    sellingEndDate: yup.date().optional()
});

const CreateTicket = () => {

    const {
        register,
        handleSubmit,
        control,
        watch,
        trigger,
        setValue,
        getValues,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(ticketSchema),
        defaultValues: {
            name: '',
            description: '',
            benefits: [],
            theme: '',
            bgImage: '',
            price: 0,
            limit: 0,
            eventDate: ''
        },
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'benefits',
    });

    const formData = watch();


    return (
        <Row className='create-ticket-container-row'>
            <Col sm={12} md={12} lg={6}>
                <div className='create-ticket-header'>Ticket Details</div>
                <Card className='create-ticket-card'>
                    <div className='ticket-accordion-container'>
                        <Accordion defaultActiveKey="0" flush className='create-ticket-accordion'>
                            <Accordion.Item eventKey={0}>
                                <Accordion.Header>General Details</Accordion.Header>
                                <Accordion.Body className='ticket-general-details-body'>
                                    <form>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <label htmlFor="name">Name</label>
                                                <input
                                                    {...register('name')}
                                                    type="text"
                                                    id="name"
                                                    placeholder='Enter name'
                                                />
                                                {errors?.name && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.name.message}</span>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <label htmlFor="description">Description</label>
                                                <div className="position-relative">
                                                    <textarea
                                                        {...register('description')}
                                                        rows={3}
                                                        id="description"
                                                        placeholder='Enter description'
                                                        maxLength={150}
                                                    />
                                                    <span className="char-count mt-1"
                                                        style={{
                                                            color: getValues('description').length === 150 ? 'red' : 'black',
                                                        }}
                                                    >
                                                        {getValues('description').length}/150
                                                    </span>
                                                </div>
                                                {errors?.description && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.description.message}</span>}
                                            </Col>
                                        </Row>
                                        <label>Benefits
                                            {
                                                errors?.benefits?.message &&
                                                <span className="text-danger" style={{ fontSize: '12px' }}>&nbsp;({errors.benefits.message})</span>
                                            }
                                        </label>
                                        <Row className='benefit-input-container'>
                                            {fields.map((field, index) => (
                                                <React.Fragment key={field.id}>
                                                    <Col sm={4} md={4} lg={4}>
                                                        <div className="position-relative">
                                                            <input
                                                                {...register(`benefits.${index}`)}
                                                                type="text"
                                                                placeholder={`Benefit ${index + 1}`}
                                                            />
                                                            <FaRegTimesCircle size={15} className='delete-benefit' onClick={() => remove(index)} />
                                                        </div>
                                                        {errors?.benefits?.[index] && (
                                                            <span className="text-danger" style={{ fontSize: '12px' }}>{errors.benefits[index].message}</span>
                                                        )}
                                                    </Col>
                                                </React.Fragment>
                                            ))}
                                            <Col sm={4} md={4} lg={4}>
                                                <button
                                                    type="button"
                                                    onClick={async () => {
                                                        const valid = await trigger('benefits'); // Trigger validation on all benefit inputs
                                                        if (fields.length <= 0 || fields.length >= 12 || valid) {
                                                            append(''); // Add a new input only if all current inputs are valid or if there are no current inputs
                                                        }
                                                    }}
                                                    className='add-benefit-btn'
                                                >
                                                    Add
                                                </button>
                                            </Col>
                                        </Row>

                                        <Row>
                                            <Col sm={12} md={12} lg={12}>
                                                <label htmlFor="price">Ticket Price</label>
                                                <input
                                                    {...register('price')}
                                                    type='number'
                                                    pattern='[0-9]*'
                                                    onInput={(e) => {
                                                        const value = e.target.value;
                                                        const regex = /^\d+(\.\d{0,2})?$/;

                                                        if (!regex.test(value)) {
                                                            e.target.value = value.slice(0, -1);
                                                        }
                                                    }}
                                                />
                                                {errors?.price && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.price.message}</span>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={6} md={6} lg={6}>
                                                <label htmlFor="date">Event Date</label>
                                                {
                                                    <Controller
                                                        control={control}
                                                        name="eventDate"
                                                        render={({ field }) => (
                                                            <input
                                                                {...field}
                                                                value={moment(field.value).format('YYYY-MM-DD')}
                                                                onChange={(date) => field.onChange(date.target.valueAsNumber)}
                                                                type='date'
                                                            />
                                                        )}
                                                    />
                                                }
                                                {errors?.eventDate && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.eventDate.message}</span>}
                                            </Col>
                                            <Col sm={6} md={6} lg={6} className='limits-col pr-0'>
                                                <label htmlFor="limit">Set Limits</label>
                                                <input {...register('limit')} type="number" id="limit" />
                                                {errors?.limit && <span className="text-danger" style={{ fontSize: '12px' }}>{errors.limit.message}</span>}
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col sm={12} md={6} lg={6}>
                                                <label htmlFor="endDate">The Selling of This Ticket Ends on</label>
                                                <input
                                                    {...register('sellingEndDate')}
                                                    type='date'
                                                />
                                            </Col>
                                        </Row>
                                    </form>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey={1}>
                                <Accordion.Header>Ticket Apperance</Accordion.Header>
                                <Accordion.Body className='ticket-theme-details-body'>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                            <label>Theme</label>
                                        </Col>
                                    </Row>
                                    <Row className='ticket-theme-row'>
                                        <Col sm={4} md={4} lg={4}>
                                            <input {...register('theme')} type="radio" value='golden-ticket' className='form-check-input custom-radio' />
                                            <div className="ticket-theme-div golden-ticket-theme"></div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <input {...register('theme')} type="radio" value='silver-ticket' className='form-check-input custom-radio' />
                                            <div className="ticket-theme-div silver-ticket-theme"></div>
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <input {...register('theme')} type="radio" value='regular-ticket' className='form-check-input custom-radio' />
                                            <div className="ticket-theme-div normal-ticket-theme"></div>
                                        </Col>
                                        {
                                            errors?.theme && (
                                                <span className="text-danger" style={{ fontSize: '12px' }}>{errors.theme.message}</span>
                                            )
                                        }
                                    </Row>
                                    <Row>
                                        <Col sm={12} md={12} lg={12}>
                                            <label>Background Image</label>
                                        </Col>
                                    </Row>
                                    <Row className='ticket-bg-img-row mb-0'>
                                        <Col sm={4} md={4} lg={4}>
                                            <input {...register('bgImage')} type="radio" value='night-club' className='form-check-input custom-radio' />
                                            <img src={ticketNightClubOption} alt='Night Club' className="ticket-bg-img" />
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <input {...register('bgImage')} type="radio" value='disco-ball' className='form-check-input custom-radio' />
                                            <img src={ticketDiscoBallOption} alt='Disco Ball' className="ticket-bg-img" />
                                        </Col>
                                        <Col sm={4} md={4} lg={4}>
                                            <input {...register('bgImage')} type="radio" value='dance-club' className='form-check-input custom-radio' />
                                            <img src={ticketDanceClubOption} alt='Dance Club' className="ticket-bg-img" />
                                        </Col>
                                        {
                                            errors?.bgImage && (
                                                <span className="text-danger" style={{ fontSize: '12px' }}>{errors.bgImage.message}</span>
                                            )
                                        }
                                    </Row>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </div>
                    <div className="create-ticket-buttons">
                        <PrimaryButton title='Create Ticket' mode='full' height='40' onClick={() => console.log(errors)} />
                    </div>
                </Card>
            </Col>
            <Col sm={12} md={12} lg={6} className='mt-md-0 mt-4'>
                <div className='create-ticket-header'>Preview</div>
                <Card className='ticket-preview-card'>
                    <div className="ticket-preview-wrapper">
                        <TicketPreview ticket={getValues()} />
                    </div>
                    <div className="mt-3 ticket-detail-content">
                        <div className="ticket-detail-header fw-semibold">
                            <p>{formData.name || 'Ticket'}</p>
                            <p>€ {formData.price || 0}</p>
                        </div>
                        <div className="ticket-detail-body">
                            <div className="ticket-detail-description">
                                {formData.description || 'Ticket Description'}
                            </div>
                            <ul style={{ width: "40%" }}>
                                {
                                    formData.benefits.length ?
                                        formData.benefits.map((benefit, index) => {
                                            return <li key={index}>{benefit}</li>
                                        })
                                        :
                                        <li>Add benefits</li>
                                }
                            </ul>
                        </div>
                    </div>
                </Card>
            </Col>
        </Row >
    )
}

export default CreateTicket
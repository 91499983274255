import React from 'react'
import '../dashboard.css';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import { motion } from 'framer-motion';

const NameAndDescription = ({
  nextSlide,
  prevSlide,
  setName,
  setDescription,
  name,
  description,
  delta,
  register,
  errors,
  isEditMode,
  editModeSaveHandler,
  isMediumOrSmallScreen
}) => {

  return (
    <motion.div
      initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.3, ease: 'easeInOut' }}
      className={`h-100 d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
      style={{ animation: isEditMode ? 'none' : '' }}
    >
      <div className='d-flex flex-fill flex-column'>
        {!isEditMode && (
          <div className='d-flex  justify-content-center mb-5'>
            <span className='title text-black fs-4'>Name & Description</span>
          </div>
        )}
        <div className=' d-flex h-100'>
          <div className={`d-flex flex-column flex-fill ${isEditMode ? 'gap-4' : 'gap-2'}`}>
            <div className='d-flex flex-column flex-fill'>
              <span className='nightspotname mb-2'>Enter Your Nightspot Name</span>
              <input
                {...register('name')}
                value={name}
                placeholder='Name'
                className='ps-3 rounded border-1 nightspotname-input'
                style={{ height: '60px' }}
                onChange={(e) => setName(e.target.value)}
                maxLength={60}>
              </input>
              {errors.name && <p className="input-error">{errors.name.message}</p>}
            </div>
            <div className="d-flex flex-column flex-fill">
              <span className="nightspotname mb-2">Enter Your Nightspot Description</span>
              <div className="position-relative">
                <textarea
                  {...register('description')}
                  onChange={(e) => setDescription(e.target.value)}
                  value={description}
                  rows="4"
                  placeholder="Description..."
                  className="ps-3 rounded pt-3 textareaDescription"
                  maxLength={280}
                  style={{ resize: 'none', outline: 'none' }}
                />
                <span className="char-count mt-1"
                  style={{
                    color: description.length === 280 ? 'red' : '',
                  }}
                >
                  {description.length}/280
                </span>
              </div>
              {errors.description && <span className="input-error">{errors.description.message}</span>}
            </div>
          </div>
        </div>
      </div>

      {
        !isMediumOrSmallScreen
          ?
          isEditMode ?
            <div className="mt-2">
              <button onClick={() => editModeSaveHandler(1)} className='edit-mode-save-btn'>Save</button>
            </div>
            :
            <div className='d-flex flex-row justify-content-between'>
              <SecondaryButton title={'Previous'} onClick={prevSlide} />
              <PrimaryButton type='submit' title={'Next'} onClick={nextSlide} />
            </div>
          :
          <></>
      }

    </motion.div>
  )
}

export default NameAndDescription
import React from "react";

import "../statistics/statistics.css";

import advertisementimage from "../../styling/imgs/advertisement.png";
import dot from "../../styling/imgs/dot.svg";
import whitearrow from "../../styling/imgs/white-arrow.png"
function ServiceAdvertisement() {
  return (
    <div className="statistics-main-div">
        <div className="statistics-child-div">
      <div className="statistics-content-div">
        <div className="statistics-left-image-div">
          <img src={advertisementimage} alt="stats" className="statistics-img" />
        </div>
        <div className="statistics-right-content-div">
          <h1 className="statistics-heading"> Advertisement</h1>

          <div className="statistics-bullet-div">
            <img src={dot} alt="dot" className="statistics-dot" />

            <p className="statistics-para">
            Make your business visible to Frizda users in Spain
            </p>
          </div>
          <div className="statistics-bullet-div">
            <img src={dot} alt="dot" className="statistics-dot" />

            <p className="statistics-para">
            Advertise your business more efficiently and effortlessly
            </p>
          </div>
          <div className="statistics-bullet-div">
            <img src={dot} alt="dot" className="statistics-dot" />

            <p className="statistics-para">
            Improve the customer experience with Frizda
            </p>
          </div>
        
         
          <div className="statistics-btn-div1">
            <button className="statistics-btn">
                <div className="statistics-btn-div">
                    <h1 className="statistics-btn-para">VIEW DETAILS</h1>
                    <div className="btn-arrow-bg">
                    <img src={whitearrow} width={15} height={19} alt="arrow" />
              </div>
                </div>
                
                </button>
          </div>
        </div>
   
      </div>
      </div>
    </div>
  );
}

export default ServiceAdvertisement;

import React from "react";

import "../marketing/Marketing.css";

import marketing from "../../styling/imgs/marketing-branding-planning-vision-goals-concept 1.png";
import dot from "../../styling/imgs/blue-dot.svg";
import blackarrow from "../../styling/imgs/bluearrow.png"

function ServiceMarketing() {
  return (
    <div className="ServiceMarketing-main-div">
        <div className="statistics-child-div">
      <div className="statistics-content-div">
      
        <div className="statistics-right-content-div">
          <h1 className="ServiceMarketing-heading"> Affiliate Marketing</h1>

          <div className="statistics-bullet-div">
            <img src={dot} alt="dot" className="statistics-dot" />

            <p className="ServiceMarketing-para">
            Convert casual visitors into qualified sales leads
            </p>
          </div>
          <div className="statistics-bullet-div">
            <img src={dot} alt="dot" className="statistics-dot" />

            <p className="ServiceMarketing-para">
            Advertise your business more effitiently and effortlessly
            </p>
          </div>
          <div className="statistics-bullet-div">
            <img src={dot} alt="dot" className="statistics-dot" />

            <p className="ServiceMarketing-para">
            Leverage affiliate partnerships for expanded reach
            </p>
          </div>
          <div className="statistics-bullet-div">
            <img src={dot} alt="dot" className="statistics-dot" />

            <p className="ServiceMarketing-para">
            Optimize campaigns for maximum conversions and ROI
            </p>
          </div>
         
          <div className="statistics-btn-div1">
            <button className="ServiceMarketing-btn">
                <div className="statistics-btn-div">
                    <h1 className="ServiceMarketing-btn-para">VIEW DETAILS</h1>
                    <div className="black-btn-arrow-bg">
                    <img src={blackarrow} width={15} height={19} alt="arrow" />
              </div>
                </div>
                
                </button>
          </div>
        </div>

        <div className="statistics-left-image-div">
          <img src={marketing} alt="stats" className="statistics-img" />
        </div>
   
      </div>
      </div>
    </div>
  );
}

export default ServiceMarketing;

import React from 'react'
import PageNotFound from '../page-not-found/PageNotFound'

const AffiliateMarketing = () => {
    return (
        <div className='w-100 h-100 p-4' style={{ backgroundColor: "#FFFFFF", borderRadius: "20px" }}>
            <PageNotFound title='Unlock Leads Soon' tagLine='Affiliate Marketing is on the way' />
        </div>
    )
}

export default AffiliateMarketing
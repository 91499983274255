import React from 'react';
import '../../styling/styles/home-page-styling/map.css'; // ← CSS file for this React compoent
import FrizdaMap from '../../FrizdaMap';

import { BrowserRouter, Link, Routes, Route } from 'react-router-dom';

export default function Map(props) {

    const locationAccess = true;

    if (locationAccess != false) {
        return (
                <div className='row map-section'>
                    <div className='map-text-container col-lg-7 d-none d-lg-flex'>
                        <h1 className='map-text'>{props.mapText} <u>{props.frizdaMap}</u></h1>
                    </div>
                    <div className='map-container-homepage col-lg-5'>
                        <div className='map mx-auto'>
                            <Link to='/map'>
                                <i className="bi bi-box-arrow-up-right" />
                            </Link>
                        </div>

                        <div className='map-text-container col-md-12 d-lg-none d-lg-flex'>
                            <h1 className='map-text mx-auto'><u>{props.frizdaMapSmall}</u></h1>
                        </div>
                    </div>
                </div>
        )
    } else {
        return (
            <div className='row map-section'>
                <div className='map-text-container col col-lg-7'>
                    <h1 className='map-text'>{props.mapText} <u>{props.frizdaMap}</u></h1>
                </div>
                <div className='map-container col col-lg-5'>
                    <div className='map'>
                        <i className="bi bi-toggle-off" />
                    </div>
                </div>
            </div>
        )
    }
}

// <div className='row map-section' style={sectionStyle}>
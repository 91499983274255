import React from 'react'
import { FaBell } from 'react-icons/fa';
import { blacklogo, userLogo } from '../../../assets/images';
import { HiMenuAlt3 } from "react-icons/hi";

const Navbar = ({ setIsSidebarCollapsed }) => {
    return (
        <nav className="navbar-container">
            <div className='w-100 d-flex d-lg-none align-items-center justify-content-between'>
                <div className='navbar-sm-logo'>
                    <img className='h-100 w-100' style={{ objectFit: 'contain' }} src={blacklogo}></img>
                </div>
                <div className='p-1' onClick={() => setIsSidebarCollapsed(prev => !prev)}>
                    <HiMenuAlt3 size={28} color='#000000' />
                </div>
            </div>
            <div className="d-none container d-lg-flex justify-content-end">
                <div className="nav-link d-flex align-items-center text-dark">
                    <FaBell size={20} />
                </div>
                <div className="nav-link d-flex align-items-center text-dark ms-4">
                    <div style={{ height: 40, width: 40 }}>
                        <img className='h-100 w-100' src={userLogo}></img>
                    </div>
                </div>
            </div>
        </nav>
    )
}

export default Navbar
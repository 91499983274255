import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'react-bootstrap';
import { FaChevronDown } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import TicketPreview from './TicketPreview'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';

// temp data table data
const SAMPLE_DATA = [
    {
        txnId: 12051,
        id: 15204,
        name: 'Splendid Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12052,
        id: 15205,
        name: 'Splendid Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12053,
        id: 15206,
        name: 'Fun Fantastic',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12054,
        id: 15207,
        name: 'Highlife Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12055,
        id: 15208,
        name: 'Splendid Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12056,
        id: 15209,
        name: 'Fun Fantastic',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12057,
        id: 15210,
        name: 'Highlife Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12058,
        id: 15211,
        name: 'Splendid Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12059,
        id: 15212,
        name: 'Fun Fantastic',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12060,
        id: 15213,
        name: 'Highlife Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12061,
        id: 15214,
        name: 'Splendid Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12062,
        id: 15215,
        name: 'Fun Fantastic',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
    {
        txnId: 12063,
        id: 15216,
        name: 'Highlife Nights',
        customerName: 'Morgan Jobs',
        paymentDate: 'June 20, 2024',
        paymentTime: '10:00 AM',
        paymentAmount: '20',
    },
];

// default data size per page
const DATA_PER_PAGE = 10;


const TicketsHome = () => {

    const SAMPLE_TICKET_DATA = [
        {
            name: 'Splendid Nights',
            theme: 'golden-ticket',
            bgImage: 'night-club',
            eventDate: 17211059620
        },
        {
            name: 'Fun Fantastic',
            theme: 'silver-ticket',
            bgImage: 'disco-ball',
            eventDate: 17211059620
        },
        {
            name: 'Highlife Nights',
            theme: 'regular-ticket',
            bgImage: 'dance-club',
            eventDate: 17211059620
        },
        {
            name: 'Splendid Nights',
            theme: 'golden-ticket',
            bgImage: 'night-club',
            eventDate: 17211059620
        },
    ];

    const [tableData, setTableData] = useState(SAMPLE_DATA);
    const navigate = useNavigate();

    const loadMoreData = () => {
        const newData = SAMPLE_DATA.slice(tableData.length, tableData.length + DATA_PER_PAGE);
        setTableData([...tableData, ...newData]);
    };

    useEffect(() => {
        setTableData(SAMPLE_DATA.slice(0, DATA_PER_PAGE));
    }, []);

    return (
        <div className='h-100 w-100'>
            <div className="tickets-dashboard-header">
                <p>Your Tickets</p>
                <button type='button' onClick={() => { navigate('/business/dashboard/tickets/create'); }}>Create Ticket</button>
            </div>

            <Row className='mt-3 mb-4'>
                <Col sm={12}>
                    <div className="tickets-swiper-wrapper">
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={20}
                            navigation={{
                                nextEl: '.swiper-right-arrow',
                                prevEl: '.swiper-left-arrow',
                            }}
                            modules={[Navigation]}
                            className="tickets-swiper"
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                },
                                576: {
                                    slidesPerView: 1,
                                },
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }}
                        >
                            {
                                SAMPLE_TICKET_DATA.map((data, index) =>
                                    <SwiperSlide key={index}>
                                        <TicketPreview ticket={data} isEditMode />
                                    </SwiperSlide>
                                )
                            }
                        </Swiper>
                        <div className="ticket-swiper-pagination">
                            <FaChevronLeft className='swiper-left-arrow' />
                            <FaChevronRight className='swiper-right-arrow' />
                        </div>
                    </div>
                </Col>
            </Row>

            <Card className='data-table-card'>
                <p className='data-table-header'>Recent Purchases</p>
                <div className="data-table-wrapper">
                    <DataTable value={tableData} className='responsive-table ticket-data-table'>
                        <Column field="txnId" header="Txn ID" headerClassName='txn-id-col' bodyClassName='txn-id-col' body={(rowData) => `#${rowData.txnId}`}></Column>
                        <Column field="id" header="Ticket ID" headerClassName='ticket-id-col' bodyClassName='ticket-id-col'></Column>
                        <Column field="name" header="Ticket Name"></Column>
                        <Column field="customerName" header="Customer Name"></Column>
                        <Column field="paymentDate" header="Payment Date"></Column>
                        <Column field="paymentTime" header="Payment Time" headerClassName='payment-time-col' bodyClassName='payment-time-col'></Column>
                        <Column field="paymentAmount" header="Payment Amount" body={(rowData) => `€ ${rowData.paymentAmount}`}></Column>
                    </DataTable>
                    <div className="data-table-footer">
                        <span style={{ cursor: 'pointer' }} onClick={loadMoreData}>
                            Show More <FaChevronDown />
                        </span>
                    </div>
                </div>
            </Card>
        </div>
    )
}

export default TicketsHome
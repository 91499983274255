import React, { useState } from 'react';
import '../../styling/styles/home-page-styling/outstandingClubs.css'; // ← CSS for this React component
import ClubCard from './OutstandingClubs-components/ClubCard';  // ← This React component's component
import { FilterOptionSelect, FilterOptionRange, FilterOptionCheckbox, FilterOptionSwitch } from './OutstandingClubs-components/FilterOptions'; // ← Another React component's component
import Yolo from '../../styling/images/Club.jpeg';
import Club2 from '../../styling/images/Club2.jpg';
import Club3 from '../../styling/images/Club3.jpeg';
import Club4 from '../../styling/images/Club5.jpeg';
import SyncLoader from "react-spinners/SyncLoader";

const musicalGenres = ['Mix', 'Reggaeton', 'Techno', 'Rap', 'Trap', 'Rock', 'Dance',
    'DeepHouse', 'Jazz', 'Bachata', 'Salsa', 'Techouse', 'House', 'Avant garde', 'Dark Techno',
    'Merengue'
];

const paymentMethods = ['Cash', 'Debit/Credit Card'];

const clubType = ['Free', 'Entrance Fee', 'Private', 'LGBTQ+', 'Women only',
    'Striptease', 'Men only'];

function OutstandingClubs(props) {

    const [locationAccess, setLocationAccess] = useState(true);

    if (locationAccess != false) {
        return (
            <div id='outstanding-clubs'>
                <div className='d-flex header-container row mb-3'>
                    <div className='col-lg-6 offset-lg-1 col-md-12'>
                        <h1 className='near-you-text'>{props.nearYouText}</h1>
                    </div>
                    <div className='col-lg-5 col-md-12 input-group my-auto search-container'>
                        <button className="btn btn-light dropdown-toggle filter-button" type="button" data-bs-toggle="dropdown" data-bs-auto-close="false" aria-expanded="false">Filters </button>
                        <input type='text' className='form-control bg-dark text-white rounded search-bar' placeholder='Search for a City' />
                        <div className='dropdown-menu text-body-secondary dropdown-menu-dark'>

                            <FilterOptionSelect
                                iconClass='bi bi-music-note-beamed'
                                selectObjectText='Select a music genre'
                                array={musicalGenres}
                                extraCustomOption={false}
                            />

                            <FilterOptionRange
                                iconClass='bi bi-signpost-fill'
                            />

                            <FilterOptionCheckbox
                                iconClass='bi bi-cash-stack'
                                array={paymentMethods}
                                inputType='checkbox'
                            />

                            <FilterOptionSwitch
                                iconClass='bi bi-badge-wc'
                                switchText='WC included'
                            />

                            <FilterOptionSelect
                                iconClass='bi bi-door-open'
                                selectObjectText='Select a club type'
                                array={clubType}
                                extraCustomOption={false}
                            />

                            <div className='apply-changes-container'>
                                <SyncLoader
                                    id='loader'
                                    color="grey"
                                    margin={2}
                                    size={4}
                                    speedMultiplier={0.8}
                                />
                                <button className='btn btn-sm btn-primary apply-changes-button' id='applyChanges'>Apply Filters</button>
                            </div>

                        </div>
                        <button className='btn search-button rounded' ><i className="bi bi-box-arrow-up-right" /></button>
                    </div>
                </div>

                <div className='row outstanded-clubs-container d-none d-lg-flex'>

                    <ClubCard
                        clubName='Winter Ball'
                        closingTime='3:30'
                        toiletCost='0.50'
                        drinksCost='€€'
                        clubCapacity='orange'
                        clubImage={Yolo}
                        distance='16'
                        paymentMethods='Cash Only'
                        musicGender='Electronic'
                    />
                    <ClubCard
                        clubName='Vanila Unicorn'
                        closingTime='24h'
                        toiletCost='FREE'
                        drinksCost='€€€'
                        clubCapacity='green'
                        clubImage={Club2}
                        distance='21'
                        paymentMethods='Cash & Cards'
                        musicGender='Mix'
                    />
                    <ClubCard
                        clubName='The Box'
                        closingTime='3:00'
                        toiletCost='1.00'
                        drinksCost='€'
                        clubCapacity='red'
                        clubImage={Club3}
                        distance='3'
                        paymentMethods='Cash & Cards'
                        musicGender='Dark Techno'
                    />
                    <ClubCard
                        clubName='Ark11'
                        closingTime='5:00'
                        toiletCost='0.80'
                        drinksCost='€€€€'
                        clubCapacity='yellow'
                        clubImage={Club4}
                        distance='26'
                        paymentMethods='Cards'
                        musicGender='House'
                    />

                </div>
            </div>
        )
    } else {
        return (

            <div id='outstanding-clubs'>
                <h1 className='near-you-text'>{props.nearYouText}... Mmh...</h1>
                <div className='no-location-container'>
                    <div>
                        <h1 className='no-location-text'><i className="bi bi-emoji-frown" /> We lost track, are you still on Earth?</h1>
                        <h1 className='no-location-text'><i class="bi bi-geo-alt" /> <a href='#'>Activate</a> your location</h1>
                    </div>
                </div>
            </div>
        )
    }

}

export default OutstandingClubs;
import React, { useState } from "react";
import LoginSpinner from "./auth-styles/spinners/ResetPassSpinner";
import "../auth-components/auth-styles/ResetPass.css";
import logo from "../auth-components/auth-styles/images/frizdausual.png";
import hide from "../auth-components/auth-styles/images/email2.png";
import show from "../auth-components/auth-styles/images/eye.png";

function ResetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [NewPassword, setNewPassword] = useState(false)
  const [showLoader, setShowLoader] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleNewPasswordVisibility = () => {
    setNewPassword(!NewPassword);
  };

  const handleSignupForm = (e) => {
    e.preventDefault();
    setShowLoader(true);
    setTimeout(() => setShowLoader(false), 1000);
  };

  return (
    <div className="business-signup-parent">
      <div className="business-signup-child">
        <div className="business-resetPass-content">
          <div className="business-login-left-div">
            <img src={logo} alt="logo" width={88} className="login-logo" />
            <h1 className="business-login-heading">Reset Password</h1>
            <p className="reset-pass-para">
              Choose a new password for your account
            </p>
          </div>
          <div className="business-login-right-div">
            <form className="reset-form" onSubmit={handleSignupForm}>
            <div
                className="hide-div-resetNewPass"
                onClick={toggleNewPasswordVisibility}
              >
                <img
                  src={NewPassword ? show : hide}
                  width="25px"
                  height="25px"
                  alt="hide"
                />
              </div>
              <input
                className="resetPassinputNewpass"
                type={NewPassword ? "text" : "password"}
                placeholder="New Password"
                required
              />
              <div
                className="hide-div-resetPass"
                onClick={togglePasswordVisibility}
              >
                <img
                  src={showPassword ? show : hide}
                  width="25px"
                  height="25px"
                  alt="hide"
                />
              </div>
              <input
                className="resetPassinputpass"
                placeholder="Confirm Password"
                type={showPassword ? "text" : "password"}
                required
              />
              <LoginSpinner
                className="login-button"
                text="Reset Password"
                onSubmit={handleSignupForm}
                loading={showLoader}
                disabled={showLoader}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ResetPassword;

import React, { useEffect, useContext } from 'react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import Hamburger from 'hamburger-react'

import "../planNav/styling/planNav.css"

import blacklogo from "../planNav/styling/imgs/black-logo.png"
import user from "../planNav/styling/imgs/user.png"
import { LoggedInContext } from '../../businessStates&Auth/businessAuthState'



function PlanNav() {
  const navigate = useNavigate();
  const [isOpen, setOpen] = useState(false);

  const { loggedIn, name } = useContext(LoggedInContext);

  useEffect(() => {

    // TODO: Make this Navbar Responsive to User authentication state
  });
  return (
    <>
      <nav className="plan-parent-nav">
        <div className="plan-navbar1">
          <div className="plan-navbar-content">
            <div className="plan-nav-left">
              <img
                src={blacklogo}
                alt="this is cone svg"
                width="96px"
                height="17px"
              />
            </div>

            <div className="plan-nav-right">
              <p className="plan-content">Home</p>
              <p className="plan-content">Features</p>
              <p className="plan-content">Pricing</p>
              <p className="plan-content">About</p>
              {/* <div className='plan-nav-user-account'>
            <img src={user} alt="user" className='user-image' height={41} width={41} />
            <div>
            <p className='plan-content'>User Name</p>
            </div>
          </div> */}
              <button className='conditional-signin-btn'>Sign in</button>
            </div>
          </div>
        </div>
      </nav>

      {/* responsive navbar */}
      <div className="responsivebg">
        <div className="res-plan-nav-humberger">
          {/* Hamburger icon */}
          {/* <Hamburger className="hamburger -test" toggled={isOpen} toggle={setOpen} /> */}
          <Hamburger color="black" toggled={isOpen} toggle={setOpen} />
          {/* Red background div */}

          <div className="plan-nav-left">
            <img
              src={blacklogo}
              alt="this is cone svg"
              width="96px"
              height="17px"
            />
          </div>
        </div>
        {isOpen && (
          <div className="plan-nav-red-bg">

            <div className="plan-nav-right-responsive">
              <p className="plan-content">Home</p>
              <p className="plan-content">Features</p>
              <p className="plan-content">Pricing</p>
              <p className="plan-content">About</p>
              {/* <div className='plan-nav-user-account'>
            <img src={user} alt="user" className='user-image' height={41} width={41} />
            <div>
            <p className='plan-content'>User Name</p>
            </div>
          </div> */}
              <button className='conditional-signin-btn'>Sign in</button>
            </div>
          </div>

        )}
      </div>
    </>
  )
}

export default PlanNav
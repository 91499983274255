import { InputSwitch } from 'primereact/inputswitch';
import React, { useEffect, useState } from 'react';
import { FaPen } from 'react-icons/fa6';
import { ticketDanceClub, ticketDiscoBall, ticketNightClub } from '../../../assets/images';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

const TicketPreview = ({ ticket, isEditMode = false }) => {

    const navigate = useNavigate();
    const [isEnabled, setIsEnabled] = useState(false);
    const [bgImage, setBgImage] = useState(null);

    useEffect(() => {
        if (ticket?.bgImage === 'night-club') {
            setBgImage(ticketNightClub);
        } else if (ticket?.bgImage === 'disco-ball') {
            setBgImage(ticketDiscoBall);
        } else if (ticket?.bgImage === 'dance-club') {
            setBgImage(ticketDanceClub);
        }
    }, [ticket]);

    return (
        <div className='ticket-preview-container'>
            <div className={`ticket-main ${ticket?.theme ? ticket?.theme : ''}-theme`}>
                <p className='ticket-name'>{ticket?.name || 'Ticket'}</p>
                <p className='ticket-date-vanue'>{ticket?.eventDate && moment(ticket?.eventDate).format('DD/MM/YYYY') || 'Date'}</p>
                {
                    bgImage ? <img src={bgImage} alt={ticket?.name} className='ticket-bg-image' /> : null
                }
            </div>
            <div className="ticket-separator"></div>
            <div className={`ticket-extra ${ticket?.theme ? ticket?.theme : ''}-extra`}>
                {
                    isEditMode &&
                    (
                        <>
                            <div className="d-flex align-items-center justify-content-center gap-1" onClick={() => navigate(`/business/dashboard/tickets/create`)}>
                                <p>EDIT</p>
                                <FaPen size={15} color='#545454' cursor='pointer' onClick={() => { }} />
                            </div>
                            {
                                ticket?.sellingEndDate &&
                                <div className="text-center">
                                    <p style={{ fontSize: '12px' }}>End Date</p>
                                    <p>{ticket?.sellingEndDate && moment(ticket?.sellingEndDate).format('DD/MM/YYYY') || ''}</p>
                                </div>
                            }
                            <div className="ticket-extra-switch-wrapper">
                                <InputSwitch className='ticket-switch' checked={isEnabled} onChange={(e) => setIsEnabled(e.value)} />
                                <p className={`${isEnabled ? '' : 'ticket-inactive'}`}>ENABLED</p>
                            </div>
                        </>
                    )
                }
            </div>
        </div>
    )
}

export default TicketPreview
import React from 'react'
import '../dashboard.css';
import { getStarted } from '../../../assets/images';
import PrimaryButton from '../components/PrimaryButton';
import { motion } from 'framer-motion';

const GetStarted = ({ nextSlide, mode, delta }) => {
    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.2, ease: 'easeInOut' }}
            className='h-100 d-flex flex-column py-4'
        >
            <div className='d-flex flex-fill flex-column justify-content-between'>
                <div className='get-started-header'>
                    <span className='title'>Nightspot Details</span>
                    <span className='text-center fw-bold' style={{ color: "#555555" }}>Start registering your nightspot on Frizda</span>
                </div>
                <div className='d-flex align-self-center' style={{ width: "80%", height: "300px" }}>
                    <img className='h-100 w-100' src={getStarted} style={{ objectFit: 'contain' }}></img>
                </div>
                <div className='px-5'>
                    <PrimaryButton title={'Get Started'} onClick={nextSlide} mode={mode} height={50} customTitleStyle='fs-5' />
                </div>
            </div>
        </motion.div>
    )
}

export default GetStarted
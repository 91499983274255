import React from 'react'
import {
    allBarOne,
    amnesia,
    belushi,
    brewdog,
    olearys,
    opium,
    fabrik
} from '../../assets/images'
import './styles/BusinessUsingFrizda.css'

const BusinessUsingFrizda = () => {
    const BUSINESS_PARTNER_LOGOS = [
        brewdog,
        olearys,
        belushi,
        allBarOne,
        opium,
        fabrik,
        amnesia,
    ]
    return (
        <div className='business-section'>
            <div className="business-header">
                <p>Businesses using <span>Frizda</span></p>
            </div>
            <div className="business-image-container">
                <div className="business-image-slider">
                    {BUSINESS_PARTNER_LOGOS.map((logo, index) => (
                        <img key={index} src={logo} alt="logo" />
                    ))}
                </div>
                <div className="business-image-slider">
                    {BUSINESS_PARTNER_LOGOS.map((logo, index) => (
                        <img key={index} src={logo} alt="logo" className='' />
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BusinessUsingFrizda
import React, { useEffect, useRef } from 'react'
import '../dashboard.css';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import { FaPlus, FaTimes } from 'react-icons/fa';
import { motion } from 'framer-motion';
import { Col, Row } from 'react-bootstrap';
import { imagePlaceholder } from '../../../assets/images';

const NightspotImages = ({
    nextSlide,
    prevSlide,
    images,
    setImages,
    delta,
    register,
    errors,
    setValue,
    isEditMode,
    editModeSaveHandler,
    isMediumOrSmallScreen
}) => {

    const fileInputRef = useRef(null);

    const openImagePicker = () => {
        fileInputRef.current.click();
    };

    const handleImageChange = (event) => {
        const selectedFiles = Array.from(event.target.files);
        const currentImageCount = images.length;
        const maxImages = 7;

        if (currentImageCount >= maxImages) {
            alert(`You can only upload ${maxImages} images.`);
            return;
        }

        const newImages = selectedFiles.slice(0, maxImages - currentImageCount).map((file) => URL.createObjectURL(file));
        setImages((prevImages) => [...prevImages, ...newImages]);
        setValue('images', newImages);
    };

    const handleRemoveImage = (index) => {
        setImages((prevImages) => prevImages.filter((_, i) => i !== index));
        setValue('images', images.filter((_, i) => i !== index));
    };

    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`h-100 d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
            style={{ animation: isEditMode ? 'none' : '' }}
        >
            <div className='d-flex flex-fill flex-column '>
                {
                    !isEditMode && (
                        <div className='d-flex justify-content-center mb-5'>
                            <span className='title text-black fs-4'>Nightspot Images</span>
                        </div>
                    )
                }

                <div className="h-100">
                    <div className='d-flex'>
                        <div className='d-flex flex-column'>
                            <div className='d-flex add-btn'>
                                <PrimaryButton type='button' title={'Add'} icon={<FaPlus size={10} />} height={30} onClick={openImagePicker} customTitleStyle='fw-medium' />
                            </div>
                            <span className='text-desc mt-2' style={{ fontFamily: 'DM Sans', color: '#555555' }}>*You can upload maximum 7 images (16/9 Ratio recommended)</span>
                        </div>
                    </div>

                    {/* Hidden File Input */}
                    <input
                        {...register('images')}
                        type="file"
                        multiple
                        accept="image/*"
                        ref={fileInputRef}
                        style={{ display: 'none' }}
                        onChange={handleImageChange}
                    />
                    <Row className='mt-2' style={{ rowGap: '10px' }}>
                        {Array.from({ length: 7 }).map((_, index) => (
                            <Col xs={6} sm={4} md={4} lg={4} key={index} className="p-0 d-flex justify-content-center align-items-center">
                                <div className="grid-image-container">
                                    <img style={{ aspectRatio: '16/9' }} src={images[index] ? images[index] : imagePlaceholder} alt={`Image ${index + 1}`} className={`${images[index] ? '' : 'grid-image-placeholder'}`} />
                                    {
                                        images[index] && <button className="remove-image-button" onClick={() => handleRemoveImage(index)}>
                                            <FaTimes size={12} color='#000' />
                                        </button>
                                    }
                                </div>
                            </Col>
                        ))}
                    </Row>
                </div>
                {/* <div className="px-5"> */}
                {errors.images && <p className='input-error'>({errors.images.message})</p>}
                {/* </div> */}

                {
                    !isMediumOrSmallScreen
                        ?
                        isEditMode ?
                            <div className="mt-2">
                                <button onClick={() => editModeSaveHandler(2)} className='edit-mode-save-btn'>Save</button>
                            </div>
                            :
                            <div className='d-flex flex-row justify-content-between'>
                                <SecondaryButton title={'Previous'} onClick={prevSlide} />
                                <PrimaryButton type='submit' title={'Next'} onClick={nextSlide} />
                            </div>
                        :
                        <></>
                }
            </div>
        </motion.div>
    )
}

export default NightspotImages
import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';

const FeeAndPayments = ({
    nextSlide,
    prevSlide,
    setPaymentModes,
    paymentModes,
    isFreeEntry,
    setIsFreeEntry,
    entranceFee,
    setEntranceFee,
    paymentOptions,
    delta,
    register,
    errors,
    setValue,
    isEditMode,
    editModeSaveHandler,
    isMediumOrSmallScreen
}) => {


    const togglePaymentMode = (id) => {
        setPaymentModes(prevModes =>
            prevModes.includes(id) ? prevModes.filter(mode => mode !== id) : [...prevModes, id]
        );
    };

    const handleFreeEntryChange = () => {
        setIsFreeEntry(!isFreeEntry);
        if (!isFreeEntry) {
            setEntranceFee({ euros: '', cents: '' });
        }

        setValue('entry.free', !isFreeEntry);
    };

    const handleFeeChange = (e, type) => {
        let value = e.target.value;

        if (type === 'cents' && value > 99) { // to ensure cents do not exceed 99
            value = 99;
        }

        setEntranceFee(prevFee => ({ ...prevFee, [type]: value }));
    };

    useEffect(() => {
        if (isFreeEntry) {
            setValue('entry.cost', '0.00');
        } else {
            setValue('entry.cost', entranceFee.euros + '.' + entranceFee.cents);
        }
    }, [entranceFee, isFreeEntry]);

    useEffect(() => {
        setValue('entry.paymentMethods', paymentModes);
    }, [paymentModes]);

    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`fee-and-payment-container h-100 d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
            style={{ animation: isEditMode ? 'none' : '' }}
        >
            <div className='d-flex flex-fill flex-column '>
                {
                    !isEditMode &&
                    <div className='d-flex justify-content-center mb-5'>
                        <span className='title text-black fs-4 '>Fee & Payments</span>
                    </div>
                }
                <div className=' d-flex flex-column h-100 justify-content-around' style={{ minHeight: isEditMode ? '300px' : '' }}>
                    {/* Entrance Fee */}
                    <div className='d-flex h-50 flex-row w-100 align-items-start justify-content-between' >
                        <div>
                            <div className='mt-2'>
                                <input
                                    type='radio'
                                    className='form-check-input custom-radio'
                                    checked={!isFreeEntry}
                                    onChange={handleFreeEntryChange}
                                />
                                <span className='text-secondary fw-semibold ms-2' style={{ fontSize: '16px' }}>Paid Entry</span>
                            </div>
                            <div className='mt-4'>
                                <input
                                    type='radio'
                                    className='form-check-input custom-radio'
                                    checked={isFreeEntry}
                                    onChange={handleFreeEntryChange}
                                />
                                <span className='text-secondary fw-semibold ms-2' style={{ fontSize: '16px' }}>Free Entry</span>
                            </div>
                        </div>
                        <div className='w-50'>
                            <span className='text-black fs-6 fw-semibold'>Entrance Fee</span>
                            <div className='d-flex flex-row mt-3'>
                                <div className='fee-payment-wrapper'>
                                    <span className='fees-span'>€</span>
                                    <input
                                        className='fees-input'
                                        value={entranceFee.euros}
                                        onChange={(e) => handleFeeChange(e, 'euros')}
                                        onKeyDown={(e) => { if (e.key === '.' || e.key === '-' || e.key === '+') e.preventDefault() }}
                                        disabled={isFreeEntry}
                                        type='number'
                                        pattern='[0-9]*'
                                    />
                                    <div className='dot-span'>
                                        <span style={{ color: '#000' }}>.</span>
                                    </div>
                                    <input className='fees-input-last'
                                        value={entranceFee.cents}
                                        onChange={(e) => handleFeeChange(e, 'cents')}
                                        onKeyDown={(e) => { if (e.key === '.' || e.key === '-' || e.key === '+') e.preventDefault() }}
                                        disabled={isFreeEntry}
                                        type='number'
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {/* Payment Modes */}
                    <div className='d-flex h-75 flex-column' >
                        <span className='text-black fs-6 fw-semibold mb-3 '>Payment Modes</span>

                        <div className='d-flex flex-row flex-wrap justify-content-between w-100 '>
                            {paymentOptions.map(option => (
                                <div
                                    key={option.id}
                                    className={`payment-mode-type-option ${paymentModes.includes(option.id) ? 'selected-payment-mode' : ''}`}
                                    style={{ width: "48%", height: "52px", cursor: 'pointer' }}
                                    onClick={() => togglePaymentMode(option.id)}
                                >
                                    {option.icon(option.label)}
                                    <span className={`text-secondary text-desc ${paymentModes.includes(option.id) ? 'selected-payment-mode-text' : ''}`} style={{ width: "80%" }}>{option.label}</span>
                                </div>
                            ))}
                        </div>
                        {errors.entry?.paymentMethods && <span className='input-error'>{errors.entry.paymentMethods.message}</span>}
                    </div>
                </div>
            </div>
            <div className="">
                {errors.entry?.cost && <span className='input-error'>{errors.entry.cost.message}</span>}
            </div>
            {
                !isMediumOrSmallScreen
                    ?
                    isEditMode ?
                        <div className="mt-2">
                            <button onClick={() => editModeSaveHandler(6)} className='edit-mode-save-btn'>Save</button>
                        </div>
                        :
                        <div className='d-flex flex-row justify-content-between'>
                            <SecondaryButton title={'Previous'} onClick={prevSlide} />
                            <PrimaryButton type='submit' title={'Next'} onClick={nextSlide} />
                        </div>
                    :
                    <></>
            }
        </motion.div>
    )
}

export default FeeAndPayments
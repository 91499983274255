import { motion } from 'framer-motion';
import React, { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import PrimaryButton from '../components/PrimaryButton';
import SecondaryButton from '../components/SecondaryButton';
import '../dashboard.css';

const MusicGenre = ({
    nextSlide,
    prevSlide,
    selectedGenre,
    setSelectedGenre,
    delta,
    register,
    errors,
    setValue,
    isEditMode,
    editModeSaveHandler,
    isMediumOrSmallScreen
}) => {
    const genres = [
        'Rock', 'Bachata', 'Jazz', 'Salsa', 'Pop', 'Techouse',
        'House', 'Reggaeton', 'Avant Garde', 'Rap', 'Dark Techno', 'Trap', 'Merengue',
        'Dance', 'EDM', 'Disco Funk', 'Country', 'Deep House', 'Hip-Hop', 'Indie', 'Reggae',
    ];

    const handleGenreChange = (genre) => {
        if (selectedGenre.length >= 3 && !selectedGenre.includes(genre)) {
            return; // Do nothing if limit is reached and the genre is not already selected
        }

        setSelectedGenre(prevGenres =>
            prevGenres.includes(genre)
                ? prevGenres.filter(d => d !== genre)
                : [...prevGenres, genre]
        );
    };

    useEffect(() => {
        setValue('musicalGenre', selectedGenre);
    }, [selectedGenre]);

    return (
        <motion.div
            initial={{ x: delta >= 0 ? '10%' : '-10%', opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className={`h-100  d-flex flex-column ${isEditMode ? 'edit-mode-padding' : 'py-4 nightspot-detail-side-padding'}`}
            style={{ animation: isEditMode ? 'none' : '' }}
        >
            <div className='d-flex flex-fill flex-column'>
                {!isEditMode &&
                    <div className='d-flex  justify-content-center align-items-center mb-2' style={{ position: 'relative' }}>
                        <span className='title text-black fs-4'>Music Genre</span>
                    </div>
                }

                <div className={`d-flex h-100 ${isEditMode ? '' : 'mt-4'}`}>
                    {/* Music Genre */}
                    <Container className='p-0 w-100' style={{ minHeight: isEditMode ? '350px' : '' }}>
                        <Row className='h-100'>
                            <span className='fw-bold' style={{ color: "#555555", fontSize: "14px" }}>
                                *Select upto 3 music genres
                                &nbsp;<span className='genre-limit'
                                    style={{
                                        color: selectedGenre.length === 3 ? 'red' : '',
                                    }}
                                >
                                    ({selectedGenre.length}/3)
                                </span>
                            </span>
                            {genres.map((genre, index) => (
                                <Col key={index} xs={6} md={4} lg={4} >
                                    <div key={index} className='form-check mb-2 '>
                                        <input
                                            // {...register(`musicalGenre[${index}]`)}
                                            type='checkbox'
                                            className='form-check-input checkbox'
                                            id={`checkbox-${genres}`}
                                            checked={selectedGenre.includes(genre)}
                                            onChange={() => handleGenreChange(genre)}
                                        />
                                        <label className='form-check-label text-black text-desc fw-semibold' style={{ fontSize: "14px" }} htmlFor={`checkbox-${genre}`}>
                                            {genre}
                                        </label>
                                    </div>
                                </Col>
                            ))}
                        </Row>
                    </Container>
                </div>
            </div>
            <div className="">
                {errors.musicalGenre && <span className='input-error'>{errors.musicalGenre.message}</span>}
            </div>
            {
                !isMediumOrSmallScreen
                    ?
                    isEditMode ?
                        <div className="mt-2">
                            <button onClick={() => editModeSaveHandler(7)} className='edit-mode-save-btn'>Save</button>
                        </div>
                        :
                        <div className='d-flex flex-row justify-content-between'>
                            <SecondaryButton title={'Previous'} onClick={prevSlide} />
                            <PrimaryButton type='submit' title={'Next'} onClick={nextSlide} />
                        </div>
                    :
                    <></>
            }
        </motion.div>
    )
}

export default MusicGenre
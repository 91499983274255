import React, { useContext, useEffect, useState } from 'react';
import { HiMenuAlt3 } from "react-icons/hi";
import { IoMdClose } from "react-icons/io";
import { Link } from 'react-router-dom';
import { userLogo, whiteLogo } from "../../assets/images";
import './styles/LandingNavbar.css';
import { LoggedInContext } from '../businessStates&Auth/businessAuthState';

const LandingNavbar = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isCollapsed, setIsCollapsed] = useState(true);

    const { loggedIn, name } = useContext(LoggedInContext);

    const toggleSidebar = () => {
        setIsCollapsed(!isCollapsed);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsScrolled(true);
            } else {
                setIsScrolled(false);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    return (
        <>
            <nav className={`nav-container ${isScrolled ? "scrolled" : ""}`}>
                <div className="nav-logo">
                    <img src={whiteLogo} alt="Frizda" className='w-100 h-100 object-fit-contain' />
                </div>
                <div className="nav-links">
                    <Link to="/business" className='nav-link'>Home</Link>
                    <Link to="/business" className='nav-link'>Features</Link>
                    <Link to="/business" className='nav-link'>Pricing</Link>
                    <Link to="/business" className='nav-link'>About</Link>

                    {!loggedIn
                        ?
                        <Link to="/business/login" className='sign-in-btn'>Login</Link>
                        :
                        <div className='nav-profile-container'>
                            <p>{name}</p>
                            <div className="nav-profile-pic">
                                <img src={userLogo} alt="Frizda" className='w-100 h-100 object-fit-contain' />
                            </div>
                        </div>
                    }
                </div>
                <div className="d-md-none d-flex align-items-center gap-2">
                    {!loggedIn
                        ?
                        <Link to="/business/login" className='sign-in-btn'>Login</Link>
                        :
                        <div className='nav-profile-container'>
                            <p>{name}</p>
                            <div className="nav-profile-pic">
                                <img src={userLogo} alt="Frizda" className='w-100 h-100 object-fit-contain' />
                            </div>
                        </div>
                    }
                    <HiMenuAlt3 size={28} color='#FFFFFF' onClick={toggleSidebar} />
                </div>
            </nav>
            <div className={`d-md-none sidebar ${isCollapsed ? "collapsed" : ""}`}>
                <div className="sidebar-links">
                    <Link to="/business" className='nav-link'>Home</Link>
                    <Link to="/business" className='nav-link'>Features</Link>
                    <Link to="/business" className='nav-link'>Pricing</Link>
                    <Link to="/business" className='nav-link'>About</Link>
                </div>
                <div className="d-block d-lg-none" style={{ position: "absolute", top: "20px", right: "20px" }}>
                    <IoMdClose size={28} color='#000000' onClick={toggleSidebar} />
                </div>
            </div>
        </>
    )
}

export default LandingNavbar
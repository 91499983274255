import "../footer/footer.css";
import axios from "axios";
import logo from "../../../frizda_business/styling/images/frizdaLogoBlack.png";
import phone from "../../../frizda_business/styling/svgs/phone.svg";
import emailImage from "../../../frizda_business/styling/svgs/email.svg";
import { Link } from "react-router-dom";
import { LoggedInContext } from "../../businessStates&Auth/businessAuthState";
import { useState, useContext, useEffect } from "react";

function Footer() {

  const { name, loggedIn, accountId } = useContext(LoggedInContext)

  const [subscribed, setSubscribed] = useState(false);
  const [alreadySubscribed, setAlreadySubscribed] = useState(false);
  const [problemSubscribing, setProblemSubscribing] = useState(false);
  const [emailUsed, setEmailUsed] = useState(false);

  useEffect(() => {
    axios.get(`http://localhost:8000/getAccountInfo/${accountId}/subscribed_to_newsletter`)
      .then(res => {
        const response = Number(res.data.field);
        if (response == 1) { setAlreadySubscribed(true) } else if (response === 0) { setAlreadySubscribed(false) }
      }).catch(err => console.error(err));
  });

  const subscribeToNewsletter = async () => {
    setSubscribed(false); setProblemSubscribing(false); setEmailUsed(false);
    const email = document.getElementById('newsletterEmail').value;
    await axios.post('http://localhost:8000/business-newlsetter-add-email', { email: email, name: loggedIn ? name : 'Dear User' })
      .then(res => {
        if (res.status == 205) {
          setSubscribed(true);
          if (loggedIn) {
            axios.put(`http://localhost:8000/updateOwnerAccount/${accountId}/subscribed_to_newsletter/${1}`)
              .then(res => console.log(res)).catch(err => console.error(err));
          }
        } else if (res.status == 409) {
          setEmailUsed(true);
        } else { setProblemSubscribing(true); }
      })
      .catch(() => setProblemSubscribing(true));
  }

  return (
    <div className="footer-parent">
      <div className="footer-parent1">
        <div className="footer-content-parent">
          <div className="footer-content">
            <div className="footer-first-parent-flex">
              {/* footer-div-1 */}
              <div className="footer-div-1">
                <img
                  src={logo}
                  width={156}
                  height={28}
                  className="footer-logo"
                  alt="this is logo svg"
                />

                <h3 className="footer-div-1-heading">Subscribe to the Newsletter</h3>
                <div className="footer-div-1-email-div">
                  <input
                    type="email"
                    disabled={subscribed || alreadySubscribed}
                    placeholder="Enter your Email"
                    className="footer-div-1-input"
                    id="newsletterEmail"
                  />
                  <button className="footer-input-button" disabled={subscribed || alreadySubscribed}>
                    <p className="footer-sub-btn-para" onClick={subscribeToNewsletter}>Subscribe</p>
                  </button>
                </div>
                {subscribed && <p className="text-success mt-2">Subscribed Succesully! Check your inbox</p>}
                {alreadySubscribed && <p className="mt-2">Subscribed</p>}
                {emailUsed && <p className="text-warning mt-2">Email already Subscribed</p>}
                {problemSubscribing && <p className="text-danger mt-2">Something went wrong, try again later</p>}
              </div>

              {/* footer-div-2 */}
              <div className="footer-div-2">
                <h3 className="footer-div-2-heading">Features</h3>
                <p className="footer-div-2-para"><a className="text-decoration-none text-reset" href="#">Frizda Coins</a></p>
                <p className="footer-div-2-para"><a className="text-decoration-none text-reset" href="#">Frizda Ads</a></p>
                <p className="footer-div-2-para"><a className="text-decoration-none text-reset" href="#">Affiliate Marketing</a></p>
                <p className="footer-div-2-para"><a className="text-decoration-none text-reset" href="#">Data</a></p>
              </div>
            </div>

            <div className="footer-secnd-parent-flex">
              {/* footer-div-3 */}
              <div className="footer-div-2 footer-div-3">
                <h3 className="footer-div-2-heading">Services</h3>
                <p className="footer-div-2-para"><a className="text-decoration-none text-reset" href="#">Plans</a></p>
                <p className="footer-div-2-para"><a className="text-decoration-none text-reset" href="#">Assistance</a></p>
                <p className="footer-div-2-para"><a className="text-decoration-none text-reset" href="#">Frizda Team</a></p>
                <p className="footer-div-2-para"><a className="text-decoration-none text-reset" href="#">Jobs</a></p>
              </div>

              {/* footer-div-4 */}
              <div className="footer-div-4">
                <h3 className="footer-div-2-heading">Contact Info</h3>
                <p className="footer-div-4-para">
                  455 West Orchard Street Kings Mountain, NC 280867
                </p>
                {/* <p className="footer-div-4-para-2">Mountain, NC 280867</p> */}
                <div className="footer-4-div-email-div">
                  <img
                    src={phone}
                    alt="this is phone svg"
                    className="footer-phone-svg"
                  />
                  <p className="footer-4-div-phone-para">
                    <a className="text-decoration-none text-reset" href="tel:+32476052008">+32 476 05 20 08</a>
                  </p>
                </div>
                <div className="footer-4-div-email-div">
                  <img
                    src={emailImage}
                    alt="this is phone svg"
                    className="footer-email-svg"
                  />
                  <p className="footer-4-div-phone-para">
                    <a className="text-decoration-none text-reset" href="mailto:frizda.business@gmail.com">
                      frizda.business@gmail.com
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>

          {/* <hr /> */}
        </div>
      </div>
      <div className="footer-div-res">
        <div className="footer-div-1-email-div">
          <input
            type="email"
            placeholder="Enter your Email"
            className="footer-div-res-input"
          />
          <button className="footer-input-res-button">
            <p className="footer-sub-btn-para">Subscribe</p>
          </button>
        </div>
        <img
          src={logo}
          className="footer-res-logo"
          alt="this is logo svg"
          width={110}
        />
      </div>
    </div>
  );
}

export default Footer;

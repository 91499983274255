import React from 'react'
import { useState, useEffect, useContext } from 'react';

import Hamburger from 'hamburger-react';

import "../service-navbar/service-navbar.css"

import blacklogo from "../../styling/imgs/frizdaLogoBlack.png"
import user from "../../styling/imgs/user.png"


function ServiceNavbar(props) {
  const [isOpen, setOpen] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);

  return (
    <>
      <nav className='service-parent-nav'>
        <div className="service-navbar1">
          <div className="service-navbar-content">
            <div className="plan-nav-left">
              <img
                src={blacklogo}
                alt="logo"
                width="110px"
                height="22px"
              />
            </div>
            <div className='nav-center'>
              <p className="plan-content">Call a Consultant</p>
              <p className="plan-content">Features</p>

            </div>
            <div className="service-plan-nav-right">
              <p className='plan-content'>{props.name} {props.lastName}</p>
              <div className='plan-nav-user-account'>
                <img src={user} alt="user" className='user-image' height={41} width={41} />
                {/* <button className="conditional-signin-btn">Sign in</button> */}
                <div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </nav>

      {/* responsive navbar */}
      <div className="responsivebg">
        <div className="res-plan-nav-humberger">
          {/* Hamburger icon */}
          <Hamburger color='black' className="hamburger -test" toggled={isOpen} toggle={setOpen} />

          {/* Red background div */}

          <div className="plan-nav-left">
            <img
              src={blacklogo}
              alt="this is cone svg"
              width="96px"
              height="17px"
            />
          </div>
        </div>
        {isOpen && (
          <div className="plan-nav-red-bg">

            <div className="plan-nav-right-responsive">
              <p className="plan-content">Call a consultant</p>
              <p className="plan-content">Features</p>
              <div className='plan-nav-user-account'>
                <img src={user} alt="user" className='user-image' height={41} width={41} />
                <div>
                  <p className='plan-content'>{props.name} {props.lastName}</p>
                </div>
                {/* <button className="conditional-signin-btn">Sign in</button> */}
              </div>
            </div>
          </div>

        )}
      </div>
    </>
  )
}

export default ServiceNavbar
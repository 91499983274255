import React from 'react';
import '../../styling/styles/home-page-styling/navbar.css'  // ← CSS file for this React component
import { Link } from 'react-router-dom';

function Navbar(props) {

    return (
        <div id='navigation-bar'>
            <nav className='navbar'>
                <a className='navbar-brand' href='/'>Frizda</a>

                <div className='navbar-items-container d-none d-lg-flex'> {/* ← Parent container of all navbar items */}
                    <div className='company-container navbar-item d-flex'>
                        <h5>{props.company}</h5>
                        <i className='bi bi-chevron-down' />
                    </div>

                    <Link className='help-container navbar-item d-flex' to='/map'>
                        <h5>{props.map}</h5>
                        <i className='bi bi-box-arrow-up-right' />
                    </Link>

                    <Link className='help-container navbar-item d-flex' to='/meet'>
                        <h5>{props.meet}</h5>
                        <i className='bi bi-box-arrow-up-right' />
                    </Link>

                    <div className='tokens-container navbar-item d-flex' to='/tokens'>
                        <h5>Tokens</h5>
                        <i className='bi bi-chevron-down' />
                    </div>
                </div>

                <div className='auth-buttons-container ml-auto d-flex'>
                    <Link className='btn btn-login' to='login' ><a>{props.login}</a></Link>
                    <Link className='btn btn-outline btn-sign-up' to='/signup' ><a>{props.sign_up}</a></Link>
                </div>


                {/* Offcanvanvas Button */}

                <div className='offcanvas-button-container d-lg-none'>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasDarkNavbar" aria-controls="offcanvasDarkNavbar" aria-label="Toggle navigation">
                        <i className="bi bi-list"></i>
                    </button>
                </div>

                {/* Offcanvas content */}

                <div class="offcanvas offcanvas-end text-bg-dark" tabindex="-1" id="offcanvasDarkNavbar" aria-labelledby="offcanvasDarkNavbarLabel">
                    <div className="offcanvas-header">
                        <h5 className="offcanvas-title" id="offcanvasDarkNavbarLabel">Offcanvas</h5>
                        <button type="button" classname="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                    <div class="dropdown mt-3">
                        <button className="btn btn-secondary dropdown-toggle offcanvas-inside-button" type="button" data-bs-toggle="dropdown">
                            Company
                        </button>
                        <ul class="dropdown-menu">
                            <li><a className="dropdown-item" href="#">Action</a></li>
                            <li><a className="dropdown-item" href="#">Another action</a></li>
                            <li><a className="dropdown-item" href="#">Something else here</a></li>
                        </ul>
                    </div>
                    <div class="offcanvas-body">
                        {/* Add your offcanvas content here */}
                    </div>
                </div>
            </nav>
        </div>
    );
}

export default Navbar;
import React, { useEffect, useState } from 'react';
import { Card, Col, Modal, Row } from 'react-bootstrap';
import { Doughnut, Line } from 'react-chartjs-2';
import PrimaryButton from '../components/PrimaryButton';
import {
    stripe,
    sumup,
    square,
    redsys,
    ingenico,
    verifone,
    zettle
} from '../../../assets/images';
import { FaChevronDown } from 'react-icons/fa';
import './monetics.css';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { FaArrowRight } from 'react-icons/fa6';
import { IoIosCloseCircle } from "react-icons/io";
import { ArcElement, Chart } from 'chart.js';

Chart.register(ArcElement);

// to set doughnut chart configuration
const doughnutChartOptions = {
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                label: function (context) {
                    return `€ ${context.raw}`;
                }
            },
            titleFont: {
                family: 'DM Sans',
                size: 12,
                weight: 'normal',
            },
            bodyFont: {
                family: 'DM Sans',
                size: 12,
                weight: 'normal',
                lineHeight: 1.2,
            },
            displayColors: false,
            backgroundColor: '#585858',
            titleColor: '#ffffff',
            bodyColor: '#ffffff',
            padding: 10,
            cornerRadius: 4,
        },
    },
    cutout: '60%', // value to set thickness of doughnut ring
};

// to set line chart configuration
const lineChartoptions = {
    maintainAspectRatio: false,
    scales: {
        x: {
            grid: {
                borderDash: [5, 5],
            },
        },
        y: {
            grid: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                stepSize: 500, // size of jump on the y axis
            },
            beginAtZero: true,
        },
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            callbacks: {
                title: function (context) {
                    return context[0].label;
                },
                label: function (context) {
                    return `Revenue: € ${context.raw}`;
                }
            },
            titleFont: {
                family: 'DM Sans',
                size: 12,
                weight: 'normal',
            },
            bodyFont: {
                family: 'DM Sans',
                size: 12,
                weight: 'normal',
                lineHeight: 1.2,
            },
            displayColors: false,
            backgroundColor: '#585858',
            titleColor: '#ffffff',
            bodyColor: '#ffffff',
            padding: 10,
            cornerRadius: 4,
            titleAlign: 'center',
            bodyAlign: 'center',
        },
    },
};

// card reader data
const CardReaders = [
    {
        id: 1,
        code: 'stripe',
        name: 'Stripe',
        image: stripe,
    },
    {
        id: 2,
        code: 'sumup',
        name: 'SumUp',
        image: sumup,
    },
    {
        id: 3,
        code: 'square',
        name: 'Square',
        image: square,
    },
    {
        id: 4,
        code: 'redsys',
        name: 'Redsys',
        image: redsys,
    },
    {
        id: 5,
        code: 'ingenico',
        name: 'Ingenico',
        image: ingenico,
    },
    {
        id: 6,
        code: 'verifone',
        name: 'Verifone',
        image: verifone,
    },
    {
        id: 7,
        code: 'zettle',
        name: 'Zettle',
        image: zettle,
    },
];

// temp data table data
const SAMPLE_DATA = [
    {
        transactionId: 1212,
        name: 'Credit Card',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Pending',
        amount: '1000',
    },
    {
        transactionId: 1213,
        name: 'Debit Card',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Completed',
        amount: '1000',
    },
    {
        transactionId: 1214,
        name: 'Apple pay',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Pending',
        amount: '1000',
    },
    {
        transactionId: 1212,
        name: 'Credit Card',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Pending',
        amount: '1000',
    },
    {
        transactionId: 1213,
        name: 'Debit Card',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Completed',
        amount: '1000',
    },
    {
        transactionId: 1214,
        name: 'Apple pay',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Pending',
        amount: '1000',
    },
    {
        transactionId: 1212,
        name: 'Credit Card',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Pending',
        amount: '1000',
    },
    {
        transactionId: 1213,
        name: 'Debit Card',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Completed',
        amount: '1000',
    },
    {
        transactionId: 1214,
        name: 'Apple pay',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Pending',
        amount: '1000',
    },
    {
        transactionId: 1212,
        name: 'Credit Card',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Pending',
        amount: '1000',
    },
    {
        transactionId: 1213,
        name: 'Debit Card',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Completed',
        amount: '1000',
    },
    {
        transactionId: 1214,
        name: 'Apple pay',
        date: 'June 20, 2024',
        time: '10:00 AM',
        status: 'Pending',
        amount: '1000',
    }
];

// default data size per page
const DATA_PER_PAGE = 10;

const MoneticsHome = () => {
    const [doughnutChartData, setDoughnutChartData] = useState({
        labels: [],
        datasets: [
            {
                data: [],
                backgroundColor: ['#7A38FE', '#8E56FE'],
                hoverBackgroundColor: ['#7A38FE', '#8E56FE'],
                borderWidth: 0,
            },
        ],
    });
    const [lineChartData, setLineChartData] = useState({
        labels: ['14/07', '14/07', '14/07', '13/07', '13/07', '12/07', '11/07', '10/07'],
        datasets: [
            {
                label: 'Revenue',
                data: [1000, 2000, 1325, 1500, 1200, 1700, 1600, 1300],
                borderColor: '#7A38FE',
                pointBackgroundColor: '#7A38FE',
                pointBorderColor: '#7A38FE',
                pointHoverBackgroundColor: '#7A38FE',
                pointHoverBorderColor: '#7A38FE',
                fill: false,
            },
        ],
    })
    const [legendData, setLegendData] = useState([]);
    const [showRedeemModal, setShowRedeemModal] = useState(false);
    const [showCardReaderModal, setShowCardReaderModal] = useState(false);
    const [selectedCardReader, setSelectedCardReader] = useState([]);
    const [tableData, setTableData] = useState(SAMPLE_DATA);
    const [selectedFilter, setSelectedFilter] = useState('lastMonth');

    // We'll write api call here to fetch chart data
    const getChartData = () => {
        // dummy data
        const data = [
            { "label": "Cards", "value": 30 },
            { "label": "Frizda Coins", "value": 20 },
            { "label": "Tokens", "value": 35 }
        ];

        const labels = data.map(item => item.label);
        const values = data.map(item => item.value);

        setDoughnutChartData({
            labels: labels,
            datasets: [
                {
                    data: values,
                    backgroundColor: ['#7A38FE', '#8E56FE', '#B692FE', '#D1A8FE'],
                    hoverBackgroundColor: ['#7A38FE', '#8E56FE', '#B692FE', '#D1A8FE'],
                    borderWidth: 0,
                },
            ]
        });

        setLegendData(data);
    }

    const loadMoreData = () => {
        const newData = SAMPLE_DATA.slice(tableData.length, tableData.length + DATA_PER_PAGE);
        setTableData([...tableData, ...newData]);
    };

    const handleSelectChange = (event) => {
        setSelectedFilter(event.target.value);
    };

    useEffect(() => {
        getChartData();
        setTableData(SAMPLE_DATA.slice(0, DATA_PER_PAGE));
    }, []);

    return (
        <div className='h-100 overflow-y-auto monetics-home-container'>
            <Row className='widget-row'>
                <Col xs={12} md={4} lg={4} className='chart-widget-col'>
                    <div className="dashboard-widget-card">
                        <div className="d-flex justify-content-between align-items-center">
                            <p className='dashboard-widget-title'>Total Income</p>
                            <div className="select-container">
                                <select className='filter-select' onChange={handleSelectChange}>
                                    <option value="lastMonth" defaultValue='lastMonth'>Last Month</option>
                                    <option value="today">Today</option>
                                    <option value="thisYear">Yesterday</option>
                                    <option value="thisWeek">This Week</option>
                                    <option value="thisMonth">This Month</option>
                                </select>
                                <FaChevronDown className="custom-arrow" />
                            </div>
                        </div>
                        <div className="d-flex h-100 gap-1">
                            <div className="income-value">
                                € 2500
                            </div>
                            <div className="w-50 d-flex justify-content-center align-items-center">
                                <div className="income-chart-container">
                                    <Doughnut data={doughnutChartData} options={doughnutChartOptions} />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex flex-wrap justify-content-center gap-3 mt-2">
                            {legendData.map((item, index) => (
                                <div key={index} className='doughnut-legend'>
                                    <div style={{ backgroundColor: doughnutChartData.datasets[0].backgroundColor[index], width: '10px', height: '10px', borderRadius: '50%', marginRight: '5px' }}></div>
                                    <span>{item.label}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4}>
                    <div className="dashboard-widget-card justify-content-between">
                        {
                            false
                                ?
                                <>
                                    <p className='dashboard-widget-title'>Redeemable Points</p>
                                    <div className="avaliable-points-container">
                                        <p className='avaliable-points'>121</p>
                                        <div className="d-flex flex-column justify-content-center">
                                            <div className="fs-4 fw-semibold">Frizda Coins</div>
                                            <span>Available</span>
                                        </div>
                                    </div>
                                    <PrimaryButton title='Redeem Now' mode='full' customBtnStyle='rounded-pill redeem-btn' onClick={() => setShowRedeemModal(true)} />
                                </>
                                :
                                <div className="widget-title w-100 h-100 d-flex justify-content-center align-items-center fs-3 fw-semibold">
                                    Coming soon...
                                </div>
                        }
                    </div>
                </Col>
                <Col xs={12} md={4} lg={4} className='cards-widget-col'>
                    <div className="dashboard-widget-card" style={{ backgroundColor: 'rgba(0, 0, 0, 0.8)' }}>
                        <div className="h-100 d-flex flex-column justify-content-between">
                            <div className="text-white text-center mt-4">Card Readers{selectedCardReader.length > 0 ? ': CONNECTED' : ' are NOT CONNECTED'}</div>
                            {
                                selectedCardReader.length > 0 ?
                                    <div className="card-reader-scrollable-wrapper">
                                        <div className="d-flex align-items-center gap-3">
                                            {
                                                selectedCardReader.map((reader, index) => (
                                                    <div key={index} className="selected-card-reader-img-wrapper">
                                                        <img src={reader.image} alt={reader.name} style={{ width: '100%', objectFit: 'contain' }} />
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                    :
                                    <></>
                            }
                            <PrimaryButton title='Connect' mode='full' customBtnStyle='card-connect-btn rounded-pill' onClick={() => setShowCardReaderModal(true)} />
                        </div>
                    </div>
                </Col>
            </Row>

            <Card className='data-table-card pb-3' style={{ height: '400px' }}>
                <div className="">
                    <p className='data-table-header'>Revenue Report</p>
                </div>
                <div className='line-chart-wrapper'>
                    <div className="line-chart-container">
                        <Line data={lineChartData} options={lineChartoptions} />
                    </div>
                </div>
            </Card>

            <Card className='data-table-card'>
                <p className='data-table-header'>Transactions</p>
                <div className="data-table-wrapper">
                    <DataTable value={tableData} className='monetics-responsive-table'>
                        <Column field="transactionId" bodyClassName='transaction-id-col' body={(rowData) => `#${rowData.transactionId}`}></Column>
                        <Column field="name" bodyClassName='name-col'></Column>
                        <Column field="date" align='center' bodyClassName='date-col'></Column>
                        <Column field="time" align='center' bodyClassName='time-col'></Column>
                        <Column field="status" align='center' bodyClassName={(rowData) => `status-col ${rowData?.status.toUpperCase() == 'COMPLETED' ? 'completed-status' : 'pending-status'}`}></Column>
                        <Column field="amount" align='center' bodyClassName='amount-col' body={(rowData) => `€ ${rowData.amount}`}></Column>
                    </DataTable>
                    <div className="data-table-footer">
                        <span style={{ cursor: 'pointer' }} onClick={loadMoreData}>
                            Show More <FaChevronDown />
                        </span>
                    </div>
                </div>
            </Card>
            <RedeemPointModal showRedeemModal={showRedeemModal} setShowRedeemModal={setShowRedeemModal} />
            <CardReaderModal showCardReaderModal={showCardReaderModal} setShowCardReaderModal={setShowCardReaderModal} setSelectedCardReader={setSelectedCardReader} selectedCardReader={selectedCardReader} />
        </div>
    )
}

export default MoneticsHome;




const RedeemPointModal = ({ showRedeemModal, setShowRedeemModal }) => {
    return (
        <Modal
            show={showRedeemModal}
            onHide={setShowRedeemModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='redeem-point-modal'
        >
            <Modal.Body>
                <p className='redeem-modal-title'>Redeem Frizda Coins</p>
                <div className="redeem-point-wrapper">
                    <p className='avaliable-points'>1,210</p>
                    <p className='fs-5' style={{ color: '#5A5A5A', fontWeight: '500' }}>Available Frizda coins</p>
                </div>
                <span className='text-left' style={{ margin: '20px 0', color: '#838383', fontSize: '14px', textAlign: 'left' }}>convert your Frizda coins to wallet money</span>
                <div className="redeem-amount-wrapper">
                    <div className="redeem-amount">
                        € 1,200
                    </div>
                    <div className='redeem-amount-btn'>
                        <span>Redeem Coins</span> <FaArrowRight size={20} />
                    </div>
                </div>
                <div className="position-absolute top-0 end-0 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => setShowRedeemModal(false)}>
                    <IoIosCloseCircle size={25} color='#7A38FE' />
                </div>
            </Modal.Body>
        </Modal >
    )
}



const CardReaderModal = ({ showCardReaderModal, setShowCardReaderModal, setSelectedCardReader, selectedCardReader }) => {
    const [tempSelectedReaders, setTempSelectedReaders] = useState([...selectedCardReader]);

    const handleSelectCardReader = (reader) => {
        const isSelected = tempSelectedReaders.some(selected => selected.id === reader.id);
        if (isSelected) {
            setTempSelectedReaders(tempSelectedReaders.filter(selected => selected.id !== reader.id));
        } else {
            setTempSelectedReaders([...tempSelectedReaders, reader]);
        }
    }

    const handleNextClick = () => {
        setSelectedCardReader(tempSelectedReaders);
        setShowCardReaderModal(false);
    }

    return (
        <Modal
            show={showCardReaderModal}
            onHide={setShowCardReaderModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='card-reader-modal'
        >
            <Modal.Body>
                <p className='redeem-modal-title'>Select the brand of Card Readers</p>

                <div className="card-reader-wrapper">
                    {CardReaders.map(reader => (
                        <div
                            key={reader.id}
                            className={`card-reader-img-wrapper ${tempSelectedReaders.some(selected => selected.id === reader.id) ? 'selected-reader' : ''}`}
                            onClick={() => handleSelectCardReader(reader)}
                        >
                            <img src={reader.image} alt={reader.name} />
                        </div>
                    ))}
                </div>

                <div className='redeem-amount-btn w-100 position-relative justify-content-center' onClick={handleNextClick}>
                    <span>Next</span> <FaArrowRight className='position-absolute' style={{ top: '50%', right: '20px', transform: 'translateY(-50%)' }} size={20} />
                </div>

                <div className="position-absolute top-0 end-0 d-flex justify-content-center align-items-center cursor-pointer" onClick={() => setShowCardReaderModal(false)}>
                    <IoIosCloseCircle size={25} color='#7A38FE' />
                </div>
            </Modal.Body>
        </Modal >
    )
}
import React from 'react';
import '../../styling/styles/home-page-styling/meet.css';  // ← CSS for this React component

function Meet(props) {
    return (
        <>
            <div className='meet-intro-color'>
                <div className='row'>
                    <div className='col-xl-6 smaller-image-parent'>
                        <div className='smaller-image-container mx-auto'>Container image 1</div>
                    </div>
                    <div className='col-xl-6'>
                        <div className='image-container mb-3 ms-5'>Container image 2</div>
                    </div>
                </div>
            </div>

            <div id='meet-section' className='row'>
                <div className='d-flex align-items-center justify-content-center col-lg-6 col-sm-12'>
                    <h1>Meet new people</h1>
                </div>
                <div className='col-lg-6'>
                    <div className='image-container offset-3 d-sm-non'>Container image 3</div>
                </div>
                <div className='image-container offset-3 mt-5'>Container image 4</div>
            </div>
        </>
    )
}

export default Meet;
import React from "react";
import { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';


import Aos from 'aos';
import "aos/dist/aos.css";

import HomePage from "./HomePage";
import FrizdaMap from "./FrizdaMap";
import SignUp from "./authentication_UI/SignUp";
import Login from "./authentication_UI/Login";
import PostAuth from "./authentication_UI/PostAuth";
import AddClub from "./frizda_business/AddClub";
import FrizdaForBusiness from "./frizda_business/FrizdaBusiness";
import SignUpForBusiness from "./frizda_business/SignUpForBusiness";
import NightclubDetails from "./frizda_business/add-nightclub-requirements/NightclubDetails";
import BusinessRoutes from "./frizda_business/business-router/BusinessRouter";

import '../src/styling/main.css' // ← Main CSS file
import 'primereact/resources/themes/saga-blue/theme.css';  // Choose the theme you prefer
import 'primereact/resources/primereact.min.css';

// ↓ Data providers

export default function App() {

    useEffect(() => {
        Aos.init();
      }, []);
    return (
        <Router>
            <div>
                <Routes>
                    <Route exact path='/' element={<HomePage />} />
                    <Route path='/map' element={<FrizdaMap />} />
                    <Route path='/signup' element={<SignUp />} />
                    <Route path='/login' element={<Login />} />
                    <Route path='/auth' element={<PostAuth />} />
                    <Route path='/business/*' element={<BusinessRoutes />} />
                </Routes>
            </div>
        </Router>
    );
};

import React, { useContext, useState } from 'react';
import { LoggedInContext } from '../businessAuthState';
import { Link, useNavigate } from 'react-router-dom';
// ↓ Backend operator
import axios from 'axios';

import "../auth-components/auth-styles/login.css"

import LoginSpinner from './auth-styles/spinners/LoginSpinner';

import logo from "../auth-components/auth-styles/images/frizdausual.png"
import googleimage from "../auth-components/auth-styles/images/google.png"
import hide from "../auth-components/auth-styles/images/email2.png";
import show from "../auth-components/auth-styles/images/eye.png"

export default function LogIn(props) {

  const navigate = useNavigate();

  const { setLoggedIn, setName, setLastName, setEmail, setAccountId, setOwnedNightspots } = useContext(LoggedInContext);

  const [userNotFound, setNoUserFound] = useState(false);
  const [otherError, setOtherError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };


  async function authenticateUser(event) {
    event.preventDefault();

        const sentEmail = document.getElementById('emailBusinessLogin').value;
        const sentPassword = document.getElementById('passwordBusinessLogin').value;
        
        const user = {
            ownerAccount: true,
            email: sentEmail,
            password: sentPassword
        }

    try {
      setNoUserFound(false);
      setOtherError(false);
      setShowLoader(true);

      await axios.post('http://localhost:8000/authenticate', user)
        .then(response => {

          if (response.status === 202) {
            setName(response.data.user.name);
            setLastName(response.data.user.lastName);
            setEmail(response.data.user.email);
            setAccountId(response.data.user.id);
            setOwnedNightspots(response.data.user.owned_nightspots);
            setLoggedIn(true);
            navigate('/business');
          }
        }).catch(error => { if (error.response.status === 507) { setNoUserFound(true) } else { setOtherError(true) } });

      setShowLoader(false);
    } catch (error) {
      console.log(error);
    }
  }


  async function loginWithGoogle() { window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=597017456043-lavr17js9qd1dbb8990o56eo8fkhcrou.apps.googleusercontent.com&response_type=code&scope=openid%20email&redirect_uri=http://localhost:3000/auth/callback' }

  return (
    <div className="business-signup-parent">
      <div className="business-signup-child">
        <div className="business-login-content">
          <div className="business-login-left-div">
            <img
              src={logo}
              alt="logo"
              width={88}
              className="login-logo"
            />
            <h1 className="business-login-heading">
              Welcome  Back
            </h1>
            <p className="login-signup-link">
              Don’t have an account?  {" "}
              <Link to="/business/signup" style={{ textDecoration: "none" }}>
                <span className="signup-link"> Sign Up </span>
              </Link>
            </p>
            <div className="or-login-div">
              <hr className="or-hr" />
              <p className="or-para">OR</p>
              <hr className="or-hr" />
            </div>
            <button className="continue-with-google-div" onClick={loginWithGoogle}>
              <img
                src={googleimage}
                width="19px"
                height="19px"
                alt="google"
                className="google-image"
              />

              <p className="continue-with-google">Continue with Google</p>
            </button>
          </div>
          <div className="business-login-right-div">
            <form className="signupform" onSubmit={authenticateUser}>


              <input
                className="signupinputemail"
                id='emailBusinessLogin'
                type="email"
                placeholder="Email"
                required
              />


              <div
                className="hide-div-login"
                onClick={togglePasswordVisibility}
              >
                <img src={showPassword ? show : hide} width="25px" height="25px" alt="hide" />

              </div>

              <input
                className="logininputpass"
                id='passwordBusinessLogin'
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                required
              />

              <Link to="/business/forgot-password" style={{ textDecoration: "none" }}>
                <p className="login-forgot-pass-para">
                  Forgot Password
                </p>
              </Link>
              {userNotFound && <p className='text-red fs-6 mt-2'>Email or Password are incorrect.</p>}
              {otherError && <p className='text-red fs-6 mt-2'>Something went wrong, try again later.</p>}
              <LoginSpinner
                className="login-button"
                text="Login"
                onSubmit={authenticateUser}
                loading={showLoader}
                disabled={showLoader}
              />
            </form>
          </div>
          {/*  */}
        </div>
      </div>
    </div>

  )
}
import React from 'react'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6'
import 'swiper/css/effect-coverflow'
import 'swiper/css/navigation'
import { EffectCoverflow, Navigation } from 'swiper/modules'
import { Swiper, SwiperSlide } from 'swiper/react'
import SAMPLE_VID from '../../assets/video/sample-video.webm'
import FAQ from './FAQ'
import './styles/HowToFrizda.css'

const HowToFrizda = () => {

    const SAMPLE_DATA = [
        {
            id: 1,
            video: SAMPLE_VID,
            title: 'How to benefit from Frizda as its best',
        },
        {
            id: 1,
            video: SAMPLE_VID,
            title: 'Advertisement on Frizda',
        },
        {
            id: 1,
            video: SAMPLE_VID,
            title: 'Let’s dive deep into the Dashboard',
        }
    ];

    return (
        <>
            <div className='how-to-container'>
                <div className="how-to-header">
                    <p>How to use <span>Frizda</span></p>
                </div>
                <div className="help-video-swiper-wrapper">
                    <Swiper
                        effect="coverflow"
                        slidesPerView={2}
                        centeredSlides={true}
                        coverflowEffect={{
                            rotate: 0,
                            stretch: 0,
                            depth: 300,
                            modifier: 2.5,
                            slideShadows: false,
                        }}
                        navigation={{
                            nextEl: '.nextArrow',
                            prevEl: '.prevArrow',
                        }}
                        loop={true}
                        modules={[Navigation, EffectCoverflow]}
                        className="d-none d-lg-block"
                        style={{ width: "100%", height: "500px" }}
                    >
                        {
                            SAMPLE_DATA.map((item) => {
                                return (
                                    <SwiperSlide key={item.id} className="video-container">
                                        <video className='w-100 h-100' controls>
                                            <source src={item.video} type="video/mp4" />
                                        </video>
                                        <p style={{ fontFamily: "'DM Sans', sans-serif" }}>{item.title}</p>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                    <div className="d-none d-lg-flex help-video-swiper-buttons">
                        <FaChevronLeft className='prevArrow' />
                        <FaChevronRight className='nextArrow' />
                    </div>

                    <Swiper
                        slidesPerView={1}
                        centeredSlides={true}
                        navigation={true}
                        modules={[Navigation]}
                        loop={true}
                        className="d-lg-none"
                        style={{ width: "90%", height: "100%" }}
                    >
                        {
                            SAMPLE_DATA.map((item) => {
                                return (
                                    <SwiperSlide key={item.id} className="video-container">
                                        <video className='w-100 h-100' controls>
                                            <source src={item.video} type="video/mp4" />
                                        </video>
                                        <p>{item.title}</p>
                                    </SwiperSlide>
                                )
                            })
                        }
                    </Swiper>
                </div>
            </div>
            <FAQ />
        </>
    )
}

export default HowToFrizda
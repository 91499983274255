import React from 'react'

const PrimaryButton = ({ title, mode, icon, height, customBtnStyle = '', customTitleStyle = '', ...props }) => {
    return (
        <button 
        className={`btn-primary rounded d-flex justify-content-around ${customBtnStyle}`}
            style={{ height: height ? height : 50, width: mode == 'full' ? '100%' : '46%', paddingLeft: "5px", paddingRight: "5px", outline: "none" }}
            {...props}
        >
            {icon && icon}
            <span className={`btn-text ${customTitleStyle}`}>{title}</span>
        </button>
    )
}

export default PrimaryButton
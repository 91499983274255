import React, { useContext, useState } from "react";
import { LoggedInContext } from "../businessStates&Auth/businessAuthState";

import "./service-page.css";

import ServiceNavbar from "./service-page-components/service-navbar";
import ServiceHero from "./service-page-components/Hero";
import Location from "./service-page-components/location";
import Statistics from "./service-page-components/statistics";
import ServiceMarketing from "./service-page-components/marketing";
import ServiceAdvertisement from "./service-page-components/advertisement";
import ServicePlans from "./service-page-components/service-page-plans";
import Footer from "../../frizda_business/home-components/footer/Footer";
import ServiceProHero from "./service-home-page-pro-hero/ServiceProHero";
import ServiceResponsive from "./service-responisve/ServiceResponsive";
import ServiceProHeroResponsive from "./service-home-page-pro-hero/ServiceProHeroRes";

function ServiceHomePage() {

  const { loggedIn, name, lastName } = useContext(LoggedInContext)

  return (
    <div>
      <ServiceNavbar
        name={name}
        lastName={lastName}
        loggedIn={loggedIn}
      />

      {/* basic home-page */}

      {/* <div className="service-web-hero">
        <ServiceHero />
      </div>
      <div className="service-res-hero">
        <ServiceResponsive />
      </div> */}

      {/*  */}

      {/* pro-home-page */}
      <div className="service-pro-web-hero">
        <ServiceProHero
          name={name}
        />
      </div>
      <div className="service-res-hero">
        <ServiceProHeroResponsive />
      </div>

      {/*  */}

      <Location />
      <Statistics />
      <ServiceMarketing />
      <ServiceAdvertisement />
      <ServicePlans />
      <Footer />
    </div>
  );
}

export default ServiceHomePage;

import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import OutstandingClubs from "./components/home-page/OutstandingClubs";
import Map from './components/home-page/Map';
import News from './components/home-page/News';
import DownloadSection from "./components/home-page/Download";
import Meet from "./components/home-page/Meet";
import Navbar from './components/home-page/Navbar';
import Footer from "./components/home-page/Footer";

let loginText = 'Log in';  // ← to be changed depending on user language
let signUpText = 'Sign Up';
let companyText = 'Company';
let meetText = 'Meet';
let mapText = 'Map'

let musicGenreSelectionText = 'Select a music genre'; // ← for outstanding clubs

export default function HomePage(props) {
    const navigate = useNavigate();
    useEffect(() => { navigate('/business') }, []);
    return (
        <>
            <Navbar
                login={loginText}
                sign_up={signUpText}
                company={companyText}
                map={mapText}
                meet={meetText}
            />

            <OutstandingClubs
                nearYouText='Outstanding Near You'
                musicGenreSelection={musicGenreSelectionText}
            />

            <Map
                mapText='Find the club that fit your vibes on'
                frizdaMap='Frizda Map'
                frizdaMapSmall='Frizda Map'
            />

            <DownloadSection
                downloadText="It's simpler in the app"
            />

            <News />

            <Meet />

            <Footer />
        </>
    )
}
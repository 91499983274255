// BusinessRoutes.js
import React from "react";
import { Route, Routes } from 'react-router-dom';
import AddClub from "../AddClub";
import SignUpForBusiness from "../SignUpForBusiness";
import NightclubDetails from "../add-nightclub-requirements/NightclubDetails";
import BusinessPlans from "../plans for business/BusinessPlans";
import CardReaders from "../add-nightclub-requirements/CardReaders";
import About from "../navbar-links/BusinessAbout";
import FrizdaCoins from "../navbar-links/FrizdaCoins";
import AccountDashboard from "../AccountDashboard";

import LogIn from "../businessStates&Auth/auth-components/LogIn";
import ForgotPassword from "../businessStates&Auth/auth-components/ForgotPassword";
import ResetPassword from "../businessStates&Auth/auth-components/ResetPassword";
import CheckEmail from "../businessStates&Auth/auth-components/CheckEmail";
import VerifyEmail from "../businessStates&Auth/auth-components/VerifyEmail";
// ↓ Data providers
import { LoggedInProvider } from "../businessStates&Auth/businessAuthState";
import { SelectedPlanProvider } from "../businessStates&Auth/SelectedPlanState";
import { NewNightspotProvider } from "../businessStates&Auth/newNighstpotState";

import PlanPage from "../plan-page";
import ServiceHomePage from "../service-home-page";
import FrizdaDashboard from "../dashboard/FrizdaDashboard";
import NightspotDetails from "../dashboard/nightspot-details/NightspotDetails";
import MoneticsHome from "../dashboard/monetics/MoneticsHome";
import ClientData from "../dashboard/client-data/ClientData";
import Tickets from "../dashboard/tickets/Tickets";
import AffiliateMarketing from "../dashboard/affiliate-marketing/AffiliateMarketing";
import FrizdaAds from "../dashboard/frizda-ads/FrizdaAds";
import LandingPage from "../landing-page/LandingPage";
import TicketsHome from "../dashboard/tickets/TicketsHome";
import { TicketDetails } from "../dashboard/tickets/TicketDetails";
import CreateTicket from "../dashboard/tickets/CreateTicket";

export default function BusinessRoutes() {
    return (
        <NewNightspotProvider>
            <LoggedInProvider>
                <SelectedPlanProvider>
                    <Routes>
                        <Route path='/account' element={<AccountDashboard />} />
                        <Route path='/frizda-coins' element={<FrizdaCoins />} />
                        <Route path='/about' element={<About />} />
                        <Route path='/' element={<LandingPage />} />
                        <Route path='/add' element={<AddClub />} />
                        <Route path='/signup' element={<SignUpForBusiness />} />
                        <Route path='/add/nightclub-details' element={<NightclubDetails />} />
                        <Route path='/add/plans' element={<BusinessPlans />} />
                        <Route path='/add/card-readers' element={<CardReaders />} />

                        {/* New Routes For Authentication--- */}
                        <Route path='/login' element={<LogIn />} />
                        <Route path='/forgot-password' element={<ForgotPassword />} />
                        <Route path='/reset-password' element={<ResetPassword />} />
                        <Route path='/check-email' element={<CheckEmail />} />
                        <Route path='/verify-email' element={<VerifyEmail />} />
                        <Route path='/plans' element={<PlanPage />} />
                        <Route path='/service-home' element={<ServiceHomePage />} />

                        {/* Dashboard Nightspot Details--- */}
                        <Route path="/dashboard" element={<FrizdaDashboard />}>
                            <Route path="frizda-ads" element={<FrizdaAds />} />
                            <Route path="nightspot-details" element={<NightspotDetails />} />
                            <Route path="monetics" element={<MoneticsHome />} />
                            <Route path="client-data" element={<ClientData />} />
                            <Route path="affiliate-marketing" element={<AffiliateMarketing />} />
                            <Route path="tickets" element={<Tickets />} >
                                <Route index element={<TicketsHome />} />
                                <Route path=":id" element={<TicketDetails />} />
                                <Route path="create" element={<CreateTicket />} />
                            </Route>
                        </Route>
                        {/* <Route path='/nightspot-dashboard' element={<FrizdaDashboard />} /> */}

                    </Routes>
                </SelectedPlanProvider>
            </LoggedInProvider>
        </NewNightspotProvider>
    );
};

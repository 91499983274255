import moment from 'moment/moment';
import React, { useEffect, useRef, useState, useContext } from 'react';
import { Accordion, Col, Modal, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { FaCircle, FaCocktail, FaEuroSign, FaGlobe, FaMapMarkerAlt, FaRegCalendarCheck, FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { HiDownload } from "react-icons/hi";
import { IoMusicalNoteSharp, IoWalletSharp } from "react-icons/io5";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from 'swiper/modules';
import { imagePlaceholder } from '../../../assets/images';
import { ReactComponent as Available } from '../../../assets/svg/available.svg';
import { ReactComponent as Cards } from '../../../assets/svg/cards.svg';
import { ReactComponent as Cash } from '../../../assets/svg/cash.svg';
import { ReactComponent as Coin } from '../../../assets/svg/coin.svg';
import { ReactComponent as Drink } from '../../../assets/svg/drink.svg';
import { ReactComponent as Food } from '../../../assets/svg/food.svg';
import Neutral from '../../../assets/svg/netural.svg';
import { ReactComponent as NotAvailable } from '../../../assets/svg/notavailable.svg';
import { ReactComponent as Token } from '../../../assets/svg/token.svg';
import '../dashboard.css';
import DrinksAndDining from '../nightspot-details-slides/DrinksAndDining';
import FeeAndPayments from '../nightspot-details-slides/FeeAndPayments';
import GetStarted from '../nightspot-details-slides/GetStarted';
import MusicGenre from '../nightspot-details-slides/MusicGenre';
import NameAndDescription from '../nightspot-details-slides/NameAndDescription';
import NightspotAddress from '../nightspot-details-slides/NightspotAddress';
import NightspotImages from '../nightspot-details-slides/NightspotImages';
import NightspotType from '../nightspot-details-slides/NightspotType';
import OpeningHours from '../nightspot-details-slides/OpeningHours';
import ToiletFacility from '../nightspot-details-slides/ToiletFacility';
import { IoMdCloseCircleOutline } from "react-icons/io";
// React Hook Form
import * as yup from "yup"; // Library for defining validation schemas
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import { nightspotSchema } from './nightspotDetailsFormSchema';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import axios from 'axios';
// Contexts
import { LoggedInContext } from '../../businessStates&Auth/businessAuthState';
import { useMediaQuery } from '@mui/material';

const chunkArray = (array, size) => {
    const result = [];
    for (let i = 0; i < array.length; i += size) {
        result.push(array.slice(i, i + size));
    }
    return result;
};

const formSteps = [
    {
        id: 'Step 1',
        fields: ['name', 'description'],
    },
    {
        id: 'Step 2',
        fields: ['images'],
    },
    {
        id: 'Step 3',
        fields: ['address.nameAdress', 'address.coordinateAddress.latitude', 'address.coordinateAddress.longitude'],
    },
    {
        id: 'Step 4',
        fields: ['nightspotType.nightclub', 'nightspotType.nightstpotOrientation'],
    },
    {
        id: 'Step 5',
        fields: ['schedule'],
    },
    {
        id: 'Step 6',
        fields: ['entry.free', 'entry.cost', 'entry.paymentMethods'],
    },
    {
        id: 'Step 7',
        fields: ['musicalGenre'],
    },
    {
        id: 'Step 8',
        fields: ['drinkSale.sellsDrinks', 'drinkSale.sellsFood', 'drinkSale.menu'],
    },
    {
        id: 'Step 9',
        fields: ['toilets.available', 'toilets.genderNeutral', 'toilets.free', 'toilets.cost'],
    },
];
const daysOfWeek = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];

const overlaySchedule = (schedule = {}) => {
    return daysOfWeek.map(day => {
        if (schedule[day] && schedule[day].opens && schedule[day].closes) {
            const opens = schedule[day].opens;
            const closes = schedule[day].closes;
            return {
                day: day.charAt(0).toUpperCase() + day.slice(1),
                time: `${moment(opens).format('HH:mm')} - ${moment(closes).format('HH:mm')}`
            };
        }
        return {
            day: day.charAt(0).toUpperCase() + day.slice(1),
            time: 'Closed'
        }
    });
};

const NightspotDetails = () => {

    const { accountId, ownedNightspots, setOwnedNightspots, loggedIn } = useContext(LoggedInContext)
    const [dbPlan, setDbPlan] = useState('');

    useEffect(() => {
        axios.get(`http://localhost:8000/getAccountInfo/${accountId}/frizdaPlan`)
            .then(res => setDbPlan(res.data.field)).catch(err => console.error(err));
        axios.get(`http://localhost:8000/getAccountInfo/${accountId}/owned_nightspots`)
            .then(res => setOwnedNightspots(res.data.field)).catch(err => console.error(err));
    }, []);

    const [selectedSlideIndex, setSelectedSlideIndex] = useState(0);
    const [previousSlideIndex, setPreviousSlideIndex] = useState(0);
    const [name, setName] = useState('');
    const [description, setDescription] = useState('');
    const [images, setImages] = useState([]);
    const [nightspotType, setNightspotType] = useState('Nightclub');
    const [paymentModes, setPaymentModes] = useState([]);
    const [diningModes, setDiningModes] = useState([]);
    const [toiletModes, setToiletModes] = useState('available');
    const [isFreeEntry, setIsFreeEntry] = useState(false);
    const [isGenderNeutralToiletAvailable, setIsGenderNeutralToiletAvailable] = useState(false);
    const [isFreeToiletEntry, setIsFreeToiletEntry] = useState(false);
    const [entranceFee, setEntranceFee] = useState({ euros: '', cents: '' });
    const [toiletEntranceFee, setToiletEntranceFee] = useState({ euros: '', cents: '' });
    const [selectedGenre, setSelectedGenre] = useState([]);
    const [menuImage, setMenuImage] = useState(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const [opensAt, setOpensAt] = useState(null);
    const [closesAt, setClosesAt] = useState(null);
    const [selectedType, setSelectedType] = useState('');
    const [showPreviewModal, setShowPreviewModal] = useState(false);
    const [showAlertModal, setShowAlertModal] = useState(false);
    const [showSaveSuccessModal, setShowSaveSuccessModal] = useState(false);

    const popoverContainerRef = useRef(null);
    const isMediumOrSmallScreen = useMediaQuery('(max-width: 991.98px)');

    const {
        register,
        handleSubmit,
        control,
        watch,
        trigger,
        setValue,
        getValues,
        clearErrors,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(nightspotSchema),
        defaultValues: {
            images: [],
            toilets: {
                available: false,
                genderNeutral: false,
                free: false,
                cost: '.'
            }
        },
    });

    const addressControlValue = watch('address');
    const nightspotSchedule = watch('schedule');

    const handleSaveForm = async (data) => {
        const updatedSchedule = { ...data.schedule };

        // to add all the days which are not selected and mark them as closed
        daysOfWeek.forEach(day => {
            if (!updatedSchedule[day]) {
                updatedSchedule[day] = { opens: null, closes: null, closed: true };
            }
        });

        const updatedData = {
            ...data,
            schedule: updatedSchedule,
            ownedBy: accountId, // Set ownedBy to accountId
            frizdaPlan: dbPlan  // Set frizdaPlan to dbPlan
        };

        console.log(updatedData);

        await axios.post('http://localhost:8000/createNewNightspot', updatedData)
            .then(res => {
                if (res.status == 207) {
                    console.log(res);

                    // if nightspot was created successfully, show success modal
                    if (ownedNightspots.length == 0) {
                        setShowSaveSuccessModal(true);
                    }
                }
            })
            .catch(err => { if (err.status == 503) { console.error(err) } });
    };

    const paymentOptions = [
        { id: 'cash', label: 'Cash', icon: () => <Cash /> },
        { id: 'frizdaCoins', label: 'Frizda Coins', icon: () => <Coin /> },
        { id: 'cards', label: 'Cards', icon: () => <Cards /> },
        { id: 'clubToken', label: `${getValues('name') ? getValues('name') : 'Club '} Token`, icon: () => <Token /> },
    ];

    const diningOptions = [
        { id: 'food', label: 'Sell Food', icon: () => <Food /> },
        { id: 'drinks', label: 'Sell Drinks', icon: () => <Drink /> },
    ];

    const toiletOptions = [
        { id: 'available', label: 'Available', icon: () => <Available /> },
        { id: 'not_available', label: 'Not Available', icon: () => <NotAvailable /> },
    ];

    const nextSlide = async () => {

        if (selectedSlideIndex > 0) {
            if (!await isFormValid(selectedSlideIndex)) {
                return;
            }
        }

        if (selectedSlideIndex < 9) {
            setSelectedSlideIndex(selectedSlideIndex + 1);
        }

        if (selectedSlideIndex === 9) {
            handleSaveForm(getValues());
        }
    };

    const prevSlide = () => {
        setSelectedSlideIndex(selectedSlideIndex - 1);
        setPreviousSlideIndex(selectedSlideIndex);
    };

    // function to check form validation based on step
    const isFormValid = async (step) => {
        let fields = formSteps[step - 1].fields; // getting fields for current slide

        if (step == 5 && selectedDays.length > 0) {
            fields = (selectedDays.map((day) => [
                `schedule.${day.toLowerCase()}.opens`,
                `schedule.${day.toLowerCase()}.closes`,
                `schedule.${day.toLowerCase()}.closed`,
            ])).flat();
        }

        const output = await trigger(fields); // triggering validations

        if (!output) {
            return false;
        }

        return true;
    }

    // Edit mode save click handler / small screen resolution save click handler
    const editModeSaveHandler = async (step) => {
        // setp will be null when handling for smaller devices
        if (step == null) {
            for (let i = 1; i <= 9; i++) {
                if (!await isFormValid(i)) {
                    // if form is not valid, show save modal
                    setShowAlertModal(true);
                    return;
                }
            }
        } else if (step > 0) { // step > 0 when handling for edit mode
            if (!await isFormValid(step)) {
                // if form is not valid, show save modal
                setShowAlertModal(true);
                return;
            }
        }

        // if there is no validation, save the form
        handleSaveForm(getValues());
    };

    const delta = selectedSlideIndex - previousSlideIndex;

    const slides = [
        {
            name: 'Get Started',
            component: <GetStarted nextSlide={nextSlide} mode={'full'} delta={delta} />,
        },
        {
            name: 'Name & Description',
            component: <NameAndDescription nextSlide={nextSlide} prevSlide={prevSlide}
                setName={setName} setDescription={setDescription} name={name}
                description={description} delta={delta} register={register} errors={errors}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
            />,
        },
        {
            name: 'Nightspot Images',
            component: <NightspotImages nextSlide={nextSlide} prevSlide={prevSlide}
                images={images}
                setImages={setImages} delta={delta}
                register={register} errors={errors} setValue={setValue}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
            />,
        },
        {
            name: 'Nightspot Address',
            component: <NightspotAddress
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                delta={delta}
                register={register}
                errors={errors}
                setValue={setValue}
                getValues={getValues}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
            />,
        },
        {
            name: 'Nightspot Type',
            component: <NightspotType
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                nightspotType={nightspotType}
                setNightspotType={setNightspotType}
                setSelectedType={setSelectedType}
                delta={delta}
                register={register}
                errors={errors}
                setValue={setValue}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
            />,
        },
        {
            name: 'Opening Hours',
            component: <OpeningHours
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                selectedDays={selectedDays}
                opensAt={opensAt}
                setOpensAt={setOpensAt}
                setSelectedDays={setSelectedDays}
                closesAt={closesAt}
                setClosesAt={setClosesAt}
                delta={delta}
                register={register}
                errors={errors}
                control={control}
                setValue={setValue}
                getValues={getValues}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
            />,
        },
        {
            name: 'Fee & Payments',
            component: <FeeAndPayments
                nextSlide={nextSlide}
                prevSlide={prevSlide}
                paymentModes={paymentModes}
                setPaymentModes={setPaymentModes} isFreeEntry={isFreeEntry}
                setIsFreeEntry={setIsFreeEntry} entranceFee={entranceFee}
                setEntranceFee={setEntranceFee} paymentOptions={paymentOptions} delta={delta}
                register={register} errors={errors} setValue={setValue}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
            />,
        },
        {
            name: 'Music Genre',
            component: <MusicGenre nextSlide={nextSlide} prevSlide={prevSlide}
                selectedGenre={selectedGenre}
                setSelectedGenre={setSelectedGenre} delta={delta}
                register={register} errors={errors} setValue={setValue}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
            />,
        },
        {
            name: 'Drinks & Dining',
            component: <DrinksAndDining nextSlide={nextSlide} prevSlide={prevSlide}
                diningOptions={diningOptions}
                diningModes={diningModes} setDiningModes={setDiningModes}
                menuImage={menuImage} setMenuImage={setMenuImage} delta={delta}
                register={register} errors={errors} setValue={setValue}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
            />,
        },
        {
            name: 'Toilet Facility',
            component: <ToiletFacility nextSlide={nextSlide} prevSlide={prevSlide}
                toiletOptions={toiletOptions} toiletModes={toiletModes}
                setToiletModes={setToiletModes} isFreeEntry={isFreeToiletEntry}
                setIsFreeEntry={setIsFreeToiletEntry} setEntranceFee={setToiletEntranceFee}
                entranceFee={toiletEntranceFee}
                isGenderNeutralToiletAvailable={isGenderNeutralToiletAvailable}
                setIsGenderNeutralToiletAvailable={setIsGenderNeutralToiletAvailable} delta={delta}
                register={register} errors={errors} setValue={setValue}
                isEditMode={isMediumOrSmallScreen || ownedNightspots}
                editModeSaveHandler={editModeSaveHandler}
                isMediumOrSmallScreen={isMediumOrSmallScreen}
                control={control}
            />,
        }
    ];

    const getFirstDayTimes = (schedule) => {
        if (!schedule) return null;

        for (const day of daysOfWeek) {
            if (schedule[day] && schedule[day].opens && schedule[day].closes) {
                const opens = schedule[day].opens;
                const closes = schedule[day].closes;
                return `${moment(opens).format('HH:mm')} - ${moment(closes).format('HH:mm')}`;
            }
        }
        return selectedDays.length > 0 ? 'Closed' : null;
    };

    useEffect(() => {
        // Remove placeholder if images are uploaded
        if (images.length > 0) {
            setImages(images);
        }
    }, [images]);

    return (
        <div className='nightspot-detail-container'>
            <Row className='w-100 nightspot-detail-row'>
                <Col xs={12} md={12} lg={6} className='sm-padding-0 nightspot-detail-col'>
                    {
                        (isMediumOrSmallScreen || ownedNightspots)
                            ?
                            <>
                                <div className="d-flex align-items-center justify-content-between" style={{ height: '30px', marginBottom: '10px' }}>
                                    <h4 className='preview-text m-0'>Nightspot Details</h4>
                                    <button onClick={() => setShowPreviewModal(true)} className='d-block d-lg-none edit-mode-save-btn mt-0' style={{ padding: '5px 10px' }}>Save</button>
                                </div>
                                <div className='edit-form-section'>
                                    <Accordion defaultActiveKey="0" flush className='custom-edit-accordion'>
                                        {slides.splice(1, 9).map((slide, index) => (
                                            <Accordion.Item key={index} eventKey={index}>
                                                <Accordion.Header>{slide.name}</Accordion.Header>
                                                <Accordion.Body>
                                                    {slide.component}
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        ))}
                                    </Accordion>
                                </div>
                            </>
                            :
                            <form onSubmit={handleSubmit(handleSaveForm)} className='slide-wrapper'>
                                {slides[selectedSlideIndex].component}
                                {selectedSlideIndex > 0 && <span className='nightspot-slide-count'>{selectedSlideIndex} / {slides.length - 1}</span>}
                            </form>
                    }
                </Col>

                {/* Preview Component */}
                <Col xs={12} md={12} lg={6} ref={popoverContainerRef} className='sm-padding-0 d-none d-lg-block'>
                    <div className='preview-content-container'>
                        <h4 className='preview-text'>Preview</h4>
                        <div className='bg-preview px-4' style={{ overflowY: 'auto' }}>
                            {/* images block */}
                            {images?.length > 0 ?
                                (
                                    <div className='position-relative'>
                                        <Swiper
                                            slidesPerView={2}
                                            spaceBetween={30}
                                            grabCursor={true}
                                            className="nightspot-preview-slider"
                                            navigation={{
                                                prevEl: ".custom-swiper-button-prev",
                                                nextEl: ".custom-swiper-button-next",
                                            }}
                                            modules={[Navigation]}
                                        >
                                            {images.map((image, imgIndex) =>
                                                <SwiperSlide key={imgIndex}>
                                                    <img className='preview-slider-image'
                                                        src={image}
                                                        alt={`Uploaded ${imgIndex}`}
                                                    />
                                                </SwiperSlide>
                                            )}
                                        </Swiper>
                                        <div className="custom-swiper-button-prev">
                                            <FaChevronLeft />
                                        </div>
                                        <div className="custom-swiper-button-next">
                                            <FaChevronRight />
                                        </div>
                                    </div>
                                )
                                :
                                <div className='d-flex flex-row justify-content-between pt-4'>
                                    <div className='d-flex image-wrapper border border-2 rounded border-white justify-content-center'>
                                        <img className='image-placeholder align-self-center'
                                            src={imagePlaceholder}
                                            style={{
                                                height: '40%',
                                                width: '30%'
                                            }}
                                            alt="Placeholder"
                                        />
                                    </div>
                                    <div className='d-flex image-wrapper border border-2 rounded border-white justify-content-center'>
                                        <img className='image-placeholder align-self-center'
                                            src={imagePlaceholder}
                                            style={{
                                                height: '40%',
                                                width: '30%'
                                            }}
                                            alt="Placeholder"
                                        />
                                    </div>
                                </div>
                            }
                            {/* name and description block */}
                            <div className='club-name-container'>
                                <span className='single-line-text limited-text'>{name ? name : 'Nightspot Name'}</span>
                                <span className='multi-line-text limited-text' style={{ opacity: '0.9' }}>
                                    {description ? description : 'Nightspot Description'}
                                </span>
                            </div>
                            {/* info block */}
                            <Row className={`nightspot-info-container ${description?.length > 150 ? 'mt-0 pb-4' : 'mt-5'}`}>
                                <Col xs={12} md={6} lg={6}>
                                    <Row className='nightspot-info-item'>
                                        <Col sm={1} md={1} lg={1}>
                                            <FaGlobe color='#fff' size={18} />
                                        </Col>
                                        <Col sm={11} md={11} lg={11}>
                                            <span className="info-text"> {nightspotType ? selectedType + " " + nightspotType : 'Nightspot Type'}</span>
                                        </Col>
                                    </Row>
                                    <Row className='nightspot-info-item'>
                                        <Col sm={1} md={1} lg={1}>
                                            <FaRegCalendarCheck color='#fff' size={18} />
                                        </Col>
                                        <Col sm={11} md={11} lg={11}>
                                            <div className="d-flex flex-wrap">
                                                <span className="info-text">
                                                    {
                                                        selectedDays.length > 0
                                                            ?
                                                            `${selectedDays[0]}: `
                                                            : 'Working Days & Time'
                                                    }
                                                </span>&nbsp;
                                                <span className="info-text p-0">
                                                    {getFirstDayTimes(nightspotSchedule)}
                                                </span>
                                            </div>
                                            <OverlayTrigger
                                                rootClose={true} trigger="click" placement="top-start"
                                                overlay={
                                                    <Popover id="popover-contained" className='schedule-popover'>
                                                        <Popover.Header as="h3" className='schedule-popover-header'>Schedule</Popover.Header>
                                                        <Popover.Body className='schedule-popover-body'>
                                                            {overlaySchedule(nightspotSchedule).map((daySchedule, index) => (
                                                                <Row key={index} className='mb-1'>
                                                                    <Col sm={5} md={5} lg={5}>
                                                                        <label className='d-flex align-items-center justify-content-between'>{daySchedule.day}</label>
                                                                    </Col>
                                                                    <Col sm={7} md={7} lg={7}>
                                                                        <span className="info-text">{daySchedule.time}</span>
                                                                    </Col>
                                                                </Row>
                                                            ))}
                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <div className="d-flex justify-content-start align-items-center cursor-pointer">
                                                    <a className="info-text" style={{ fontSize: '12px', color: '#fff' }}>See Full Schedule</a>
                                                </div>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                    <Row className='nightspot-info-item'>
                                        <Col sm={1} md={1} lg={1}>
                                            <FaEuroSign color='#fff' size={18} />
                                        </Col>
                                        <Col sm={11} md={11} lg={11}>
                                            <span className="info-text">{isFreeEntry ? 'Free Entry' : (entranceFee.euros ? `${entranceFee.euros}.${entranceFee.cents ? entranceFee.cents : '00'} Entrance Fee` : 'Entrance Fee')}</span>
                                        </Col>
                                    </Row>
                                    <Row className='nightspot-info-item'>
                                        <Col sm={1} md={1} lg={1}>
                                            <IoWalletSharp color='#fff' size={18} />
                                        </Col>
                                        <Col sm={11} md={11} lg={11}>
                                            <div className='d-flex'>
                                                <span className="info-text">Payment Mode</span>
                                            </div>
                                            <div className="d-flex flex-wrap">
                                                <span className="info-text">{paymentModes.map((item, index) => paymentOptions.find((option) => option.id === item).label).join(', ')}</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>

                                <Col xs={12} md={6} lg={6}>
                                    <Row className='nightspot-info-item'>
                                        <Col sm={1} md={1} lg={1}>
                                            <FaMapMarkerAlt color='#fff' size={18} />
                                        </Col>
                                        <Col sm={11} md={11} lg={11}>
                                            <span className="info-text" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', display: 'block' }}>{addressControlValue?.nameAdress ? addressControlValue?.nameAdress : 'Address'}</span>
                                            <OverlayTrigger
                                                rootClose={true} trigger="click" placement="top-end"
                                                overlay={
                                                    <Popover id="popover-contained" className='schedule-popover'>
                                                        <Popover.Header as="h3" className='schedule-popover-header'>Coordinates</Popover.Header>
                                                        <Popover.Body className='schedule-popover-body'>
                                                            <Row className='mb-1'>
                                                                <Col sm={4} md={4} lg={4}>
                                                                    <label className='d-flex align-items-center justify-content-between'>Address</label>
                                                                </Col>
                                                                <Col sm={8} md={8} lg={8}>
                                                                    {addressControlValue?.nameAdress}
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-1'>
                                                                <Col sm={4} md={4} lg={4}>
                                                                    <label className='d-flex align-items-center justify-content-between'>Latitude</label>
                                                                </Col>
                                                                <Col sm={8} md={8} lg={8}>
                                                                    {addressControlValue?.coordinateAddress?.latitude} °N
                                                                </Col>
                                                            </Row>
                                                            <Row className='mb-1'>
                                                                <Col sm={4} md={4} lg={4}>
                                                                    <label className='d-flex align-items-center justify-content-between'>Longitude</label>
                                                                </Col>
                                                                <Col sm={8} md={8} lg={8}>
                                                                    {addressControlValue?.coordinateAddress?.longitude} °E
                                                                </Col>
                                                                <Col sm={8} md={8} lg={8}>
                                                                    <button
                                                                        className='btn btn-secondary btn-sm mt-2'
                                                                        onClick={() => {
                                                                            const latitude = addressControlValue?.coordinateAddress?.latitude;
                                                                            const longitude = addressControlValue?.coordinateAddress?.longitude;
                                                                            const nameAddress = addressControlValue?.nameAddress;
                                                                            let url = '';

                                                                            if (latitude && longitude) {
                                                                                url = `https://www.google.com/maps?q=${latitude},${longitude}`;
                                                                            } else if (nameAddress) {
                                                                                url = `https://www.google.com/maps?q=${nameAddress}`;
                                                                            }

                                                                            if (url) {
                                                                                window.open(url, '_blank');
                                                                            }
                                                                        }}
                                                                        disabled={!addressControlValue?.coordinateAddress?.longitude && !addressControlValue?.nameAddress}
                                                                    >
                                                                        Google Maps
                                                                    </button>
                                                                </Col>
                                                            </Row>


                                                        </Popover.Body>
                                                    </Popover>
                                                }
                                            >
                                                <div className="d-flex justify-content-start align-items-center cursor-pointer">
                                                    <a className="info-text" style={{ fontSize: '12px', color: '#fff' }}>Get Directions</a>
                                                </div>
                                            </OverlayTrigger>
                                        </Col>
                                    </Row>
                                    <Row className='nightspot-info-item'>
                                        <Col sm={1} md={1} lg={1}>
                                            <IoMusicalNoteSharp color='#fff' size={18} />
                                        </Col>
                                        <Col sm={11} md={11} lg={11}>
                                            <div className="d-flex flex-wrap">
                                                {selectedGenre.length > 0 ? <span className='info-text'>{selectedGenre.join(', ')}</span> : <span className='info-text'>Music Genre</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className='nightspot-info-item'>
                                        <Col sm={1} md={1} lg={1}>
                                            {isGenderNeutralToiletAvailable ? <img src={Neutral} style={{ width: '18px', height: '18px' }} /> : <FaCircle color='#fff' size={18} />}
                                        </Col>
                                        <Col sm={11} md={11} lg={11}>
                                            <div className="d-flex flex-wrap">
                                                {toiletModes === 'available' ? <span className="info-text">Toilets Available</span> : <span className="info-text">Toilets Unavailable</span>}
                                            </div>
                                            {toiletModes === 'available' && <div className='d-flex justify-content-start align-items-center'>
                                                <span className="info-text">{isFreeToiletEntry ? 'Free' : (toiletEntranceFee.euros ? `€ ${toiletEntranceFee.euros}.${toiletEntranceFee.cents ? toiletEntranceFee.cents : '00'} ` : 'Price')}</span>
                                            </div>}
                                        </Col>
                                    </Row>
                                    <Row className='nightspot-info-item'>
                                        <Col sm={1} md={1} lg={1}>
                                            <FaCocktail color='#fff' size={18} />
                                        </Col>
                                        <Col sm={11} md={11} lg={11}>
                                            <div className="d-flex">
                                                {diningModes.length > 0 ?
                                                    <span className="info-text">
                                                        {diningOptions.length > 1
                                                            ?
                                                            diningModes.map((item) => diningOptions.find((option) => option.id === item).label).join(', ')
                                                            :
                                                            diningOptions.find((option) => option.id === diningModes[0]).label
                                                        }
                                                    </span>
                                                    :
                                                    <span className="info-text">Dining & Drinks</span>
                                                }
                                            </div>
                                            {menuImage &&
                                                <div className="d-flex justify-content-start align-items-center cursor-pointer gap-1">
                                                    <HiDownload size={18} /> <a href={menuImage} download={`${name} Menu`} target='_blank' style={{ fontSize: '12px', color: '#fff' }}>Download Menu</a>
                                                </div>
                                            }
                                        </Col>
                                    </Row>
                                </Col>
                                {/* {ownedNightspots.length > 0 && <button disabled={isFormComplete} className='position-absolute bottom-0 mb-4 w-25'>{ownedNightspots ? 'Save Changes' : 'Upload'}</button>} */}
                            </Row>
                        </div>
                    </div>
                </Col>
            </Row>
            <PreviewModal show={showPreviewModal} onHide={() => setShowPreviewModal(false)} data={getValues()} checkFormValidation={() => editModeSaveHandler(null)} />
            <AlertModal show={showAlertModal} onHide={() => setShowAlertModal(false)} />
            <SaveSuccessModal show={showSaveSuccessModal} onHide={() => setShowSaveSuccessModal(false)} />
        </div>
    );
};

export default NightspotDetails;




























// Preview Modal Component
// For small resolution devices, we will show preview modal on save button click
// if there is invalid form field, we will show save modal
// else we will save the data (call the API)
const PreviewModal = ({ show, onHide, data, checkFormValidation }) => {

    const paymentOptions = [
        { id: 'cash', label: 'Cash' },
        { id: 'frizdaCoins', label: 'Frizda Coins' },
        { id: 'cards', label: 'Cards' },
        { id: 'clubToken', label: `${data?.name || '' + 'Club '} Token` },
    ];

    const scheduleEntries = Object.entries(data?.schedule ?? {});
    const getScheduleDay = () => {
        if (scheduleEntries.length == 0) {
            return {
                day: '',
                daySchedule: 'Closed'
            };
        }
        const [firstDay, firstDaySchedule] = scheduleEntries[0];
        const opens = firstDaySchedule.opens;
        const closes = firstDaySchedule.closes;

        if (!opens || !closes) {
            return {
                day: `${firstDay.charAt(0).toUpperCase() + firstDay.slice(1)}`,
                daySchedule: 'Closed'
            };
        }

        return {
            day: `${firstDay.charAt(0).toUpperCase() + firstDay.slice(1)}`,
            daySchedule: `${moment(firstDaySchedule.opens).format('HH:mm')}-${moment(firstDaySchedule.closes).format('HH:mm')}`
        };
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='preview-modal'
        >
            <div style={{ position: "absolute", top: "6px", right: "6px", zIndex: 1 }}>
                <IoMdCloseCircleOutline size={28} color='#FFFFFF' onClick={onHide} />
            </div>
            <Row style={{ overflow: 'hidden' }}>
                <Col xs={12} md={12} lg={12} className='p-0 position-relative'>
                    <div className='bg-preview'>
                        {/* images block */}
                        {data?.images?.length > 0 ?
                            (
                                <div className='position-relative'>
                                    <Swiper
                                        slidesPerView={2}
                                        spaceBetween={30}
                                        grabCursor={true}
                                        className="nightspot-preview-slider m-0"
                                        navigation={{
                                            prevEl: ".custom-swiper-button-prev",
                                            nextEl: ".custom-swiper-button-next",
                                        }}
                                        modules={[Navigation]}
                                    >
                                        {data?.images.map((image, imgIndex) =>
                                            <SwiperSlide key={imgIndex}>
                                                <img className='preview-slider-image'
                                                    src={image}
                                                    alt={`Uploaded ${imgIndex}`}
                                                />
                                            </SwiperSlide>
                                        )}
                                    </Swiper>
                                    <div className="custom-swiper-button-prev" style={{ left: 0, zIndex: 10 }}>
                                        <FaChevronLeft />
                                    </div>
                                    <div className="custom-swiper-button-next" style={{ right: 0, zIndex: 10 }}>
                                        <FaChevronRight />
                                    </div>
                                </div>
                            )
                            :
                            <div className='d-flex flex-row justify-content-between'>
                                <div className='d-flex image-wrapper border border-2 rounded border-white justify-content-center' style={{ minWidth: '48%' }}>
                                    <img className='image-placeholder align-self-center'
                                        src={imagePlaceholder}
                                        style={{
                                            height: '40%',
                                            width: '30%'
                                        }}
                                        alt="Placeholder"
                                    />
                                </div>
                                <div className='d-flex image-wrapper border border-2 rounded border-white justify-content-center' style={{ minWidth: '48%' }}>
                                    <img className='image-placeholder align-self-center'
                                        src={imagePlaceholder}
                                        style={{
                                            height: '40%',
                                            width: '30%'
                                        }}
                                        alt="Placeholder"
                                    />
                                </div>
                            </div>
                        }
                        {/* name and description block */}
                        <div className='club-name-container'>
                            <span className='single-line-text limited-text'>{data?.name ? data?.name : 'Nightspot Name'}</span>
                            <span className='multi-line-text limited-text' style={{ opacity: '0.9' }}>
                                {data?.description ? data?.description : 'Nightspot Description'}
                            </span>
                        </div>
                        {/* info block */}
                        <Row className={`nightspot-info-container ${data?.description?.length > 150 ? 'mt-0 pb-4' : 'mt-5'}`}>
                            <Col xs={6} md={6} lg={6} className='pl-0'>
                                <Row className='nightspot-info-item'>
                                    <Col xs={1} md={1} lg={1}>
                                        <FaGlobe color='#fff' size={18} />
                                    </Col>
                                    <Col xs={11} md={11} lg={11}>
                                        <span className="info-text">{
                                            (data?.nightspotType?.nightclub)
                                                ? `${data?.nightspotType?.nightstpotOrientation ? data?.nightspotType?.nightstpotOrientation : ''} Nightclub`
                                                : (!data?.nightspotType?.nightclub)
                                                    ? `${data?.nightspotType?.nightstpotOrientation} Bar`
                                                    : 'Nightspot Type'}</span>
                                    </Col>
                                </Row>
                                <Row className='nightspot-info-item'>
                                    <Col xs={1} md={1} lg={1}>
                                        <FaRegCalendarCheck color='#fff' size={18} />
                                    </Col>
                                    <Col xs={11} md={11} lg={11}>
                                        <div className="d-flex flex-wrap">
                                            <span className="info-text">
                                                {
                                                    scheduleEntries.length > 0
                                                        ?
                                                        `${getScheduleDay().day}: `
                                                        : 'Working Days & Time'
                                                }
                                            </span>&nbsp;
                                            <span className="info-text px-0">
                                                {getScheduleDay().daySchedule}
                                            </span>
                                        </div>
                                        <OverlayTrigger
                                            rootClose={true} trigger="click" placement="top-start"
                                            overlay={
                                                <Popover id="popover-contained" className='schedule-popover'>
                                                    <Popover.Header as="h3" className='schedule-popover-header'>Schedule</Popover.Header>
                                                    <Popover.Body className='schedule-popover-body'>
                                                        {overlaySchedule(data?.schedule).map((daySchedule, index) => (
                                                            <Row key={index} className='mb-1'>
                                                                <Col xs={5} md={5} lg={5}>
                                                                    <label className='d-flex align-items-center justify-content-between'>{daySchedule.day}</label>
                                                                </Col>
                                                                <Col xs={7} md={7} lg={7}>
                                                                    <span className="info-text">{daySchedule.time}</span>
                                                                </Col>
                                                            </Row>
                                                        ))}
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center cursor-pointer">
                                                <a className="info-text" style={{ fontSize: '12px', color: '#fff' }}>See Full Schedule</a>
                                            </div>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <Row className='nightspot-info-item'>
                                    <Col xs={1} md={1} lg={1}>
                                        <FaEuroSign color='#fff' size={18} />
                                    </Col>
                                    <Col xs={11} md={11} lg={11}>
                                        <span className="info-text">{data?.entry?.free ? 'Free Entry' : ((data?.entry?.cost != '.') ? `${data?.entry?.cost} Entrance Fee` : 'Entrance Fee')}</span>
                                    </Col>
                                </Row>
                                <Row className='nightspot-info-item'>
                                    <Col xs={1} md={1} lg={1}>
                                        <IoWalletSharp color='#fff' size={18} />
                                    </Col>
                                    <Col xs={11} md={11} lg={11}>
                                        <div className='d-flex'>
                                            <span className="info-text">Payment Mode</span>
                                        </div>
                                        <div className="d-flex flex-wrap">
                                            <span className="info-text">
                                                {(data?.entry?.paymentMethods?.length > 0) && data?.entry?.paymentMethods.map((item) => paymentOptions.find((option) => option.id === item).label).join(', ')}
                                            </span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>

                            <Col xs={6} md={6} lg={6} className='pr-0' style={{ height: '300px' }}>
                                <Row className='nightspot-info-item'>
                                    <Col xs={1} md={1} lg={1}>
                                        <FaMapMarkerAlt color='#fff' size={18} />
                                    </Col>
                                    <Col xs={11} md={11} lg={11}>
                                        <span className="info-text" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap', display: 'block' }}>{data?.address?.nameAdress ? data?.address?.nameAdress : 'Address'}</span>
                                        <OverlayTrigger
                                            rootClose={true} trigger="click" placement="top-end"
                                            overlay={
                                                <Popover id="popover-contained" className='schedule-popover'>
                                                    <Popover.Header as="h3" className='schedule-popover-header'>Coordinates</Popover.Header>
                                                    <Popover.Body className='schedule-popover-body'>
                                                        <Row className='mb-1'>
                                                            <Col xs={4} md={4} lg={4}>
                                                                <label className='d-flex align-items-center justify-content-between'>Address</label>
                                                            </Col>
                                                            <Col xs={8} md={8} lg={8}>
                                                                {data?.address?.nameAdress}
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-1'>
                                                            <Col xs={4} md={4} lg={4}>
                                                                <label className='d-flex align-items-center justify-content-between'>Latitude</label>
                                                            </Col>
                                                            <Col xs={8} md={8} lg={8}>
                                                                {data?.address?.coordinateAddress?.latitude} °N
                                                            </Col>
                                                        </Row>
                                                        <Row className='mb-1'>
                                                            <Col xs={4} md={4} lg={4}>
                                                                <label className='d-flex align-items-center justify-content-between'>Longitude</label>
                                                            </Col>
                                                            <Col xs={8} md={8} lg={8}>
                                                                {data?.address?.coordinateAddress?.longitude} °E
                                                            </Col>
                                                        </Row>
                                                    </Popover.Body>
                                                </Popover>
                                            }
                                        >
                                            <div className="d-flex justify-content-start align-items-center cursor-pointer">
                                                <a className="info-text" style={{ fontSize: '12px', color: '#fff' }}>Get Directions</a>
                                            </div>
                                        </OverlayTrigger>
                                    </Col>
                                </Row>
                                <Row className='nightspot-info-item'>
                                    <Col xs={1} md={1} lg={1}>
                                        <IoMusicalNoteSharp color='#fff' size={18} />
                                    </Col>
                                    <Col xs={11} md={11} lg={11}>
                                        <div className="d-flex flex-wrap">
                                            {data?.musicalGenre?.length > 0 ? <span className='info-text'>{data?.musicalGenre?.join(', ')}</span> : <span className='info-text'>Music Genre</span>}
                                        </div>
                                    </Col>
                                </Row>
                                <Row className='nightspot-info-item'>
                                    <Col xs={1} md={1} lg={1}>
                                        {data?.toilets?.genderNeutral ? <img src={Neutral} style={{ width: '18px', height: '18px' }} /> : <FaCircle color='#fff' size={18} />}
                                    </Col>
                                    <Col xs={11} md={11} lg={11}>
                                        <div className="d-flex flex-wrap">
                                            {data?.toilets?.available ? <span className="info-text">Toilets Available</span> : <span className="info-text">Toilets Unavailable</span>}
                                        </div>
                                        {data?.toilets?.available && <div className='d-flex justify-content-start align-items-center'>
                                            <span className="info-text">{data?.toilets?.free ? 'Free' : (data?.toilets?.cost ? `€ ${data?.toilets?.cost}` : 'Price')}</span>
                                        </div>}
                                    </Col>
                                </Row>
                                <Row className='nightspot-info-item'>
                                    <Col xs={1} md={1} lg={1}>
                                        <FaCocktail color='#fff' size={18} />
                                    </Col>
                                    <Col xs={11} md={11} lg={11}>
                                        <div className="d-flex">
                                            <span className="info-text">
                                                {
                                                    (data?.drinkSale?.sellsFood && data?.drinkSale?.sellsDrinks) ?
                                                        "Sells Food, Sells Drinks"
                                                        :
                                                        data?.drinkSale?.sellsFood ? 'Sells Food'
                                                            :
                                                            data?.drinkSale?.sellsDrinks ? 'Sells Drinks'
                                                                :
                                                                'Dining & Drinks'
                                                }
                                            </span>
                                        </div>
                                        {data?.drinkSale?.menu &&
                                            <div className="d-flex justify-content-start align-items-center cursor-pointer gap-1">
                                                <HiDownload size={18} /> <a href={data?.drinkSale?.menu} download={`${data?.name} Menu`} target='_blank' style={{ fontSize: '12px', color: '#fff' }}>Download Menu</a>
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </div>
                    <div className="position-absolute bottom-0 left-0 w-100">
                        <button onClick={checkFormValidation} className='w-50 mx-auto fs-6 preview-save-btn' style={{ padding: '5px 10px' }}>Save</button>
                    </div>
                </Col>
            </Row>
        </Modal>
    );
}




// Confirm Save Modal, it will be shwown when form is not valid
const AlertModal = ({ show, onHide }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='save-changes-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Alert
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ alignItems: 'start' }}>
                <p style={{ fontSize: '1rem' }}>
                    Please enter all required information.
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className='edit-mode-save-btn' style={{ padding: '10px 20px', fontSize: '1rem' }} type='button' onClick={onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}


// Success Modal, it will be shwown when ownedNightspots.length > 0 form is successfully saved
const SaveSuccessModal = ({ show, onHide }) => {
    return (
        <Modal
            show={show}
            onHide={onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className='save-changes-modal'
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    Successs
                </Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ alignItems: 'start' }}>
                <p style={{ fontSize: '1rem' }}>
                    Nightspot Uploaded Successfully
                </p>
            </Modal.Body>
            <Modal.Footer>
                <button className='edit-mode-save-btn' style={{ padding: '10px 20px', fontSize: '1rem' }} type='button' onClick={onHide}>Close</button>
            </Modal.Footer>
        </Modal>
    )
}
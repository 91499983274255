import React, { useEffect, useContext, useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { LoggedInContext } from "../businessAuthState";
import "../auth-components/auth-styles/signup.css"; // ← CSS file for comnponent
import { useNavigate } from 'react-router-dom';

import logoImage from "../auth-components/auth-styles/images/frizdausual.png";
import googleImage from "../auth-components/auth-styles/images/google.png";
import hide from "../auth-components/auth-styles/images/email2.png";
import bullet from "../auth-components/auth-styles/svgs/bullet.svg";
import show from "../auth-components/auth-styles/images/eye.png"

import Spinner from "./auth-styles/spinners/Spinner";

export default function SignUp(props) {
  const { setEmail, setLastName, setName, setLoggedIn, setAccountId } = useContext(LoggedInContext)
  const navigate = useNavigate();

  const [signupPassword, setsignupPassword] = useState("");
  const [confirmPassword, setconfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const [passwordsMatch, setPasswordsMatch] = useState(true);
  // ↓ For password requirements
  const [lacksLength, setLacksLength] = useState(false);
  const [lacksChar, setLacksChar] = useState(false);
  const [lacksUpper, setLacksUpper] = useState(false);
  const [lacksNumber, setLacksNumber] = useState(false);
  const [lacksLower, setLacksLower] = useState(false);

  function validatePassword(password) {
    // Reset all state variables
    setLacksLength(false);
    setLacksChar(false);
    setLacksUpper(false);
    setLacksNumber(false);
    setLacksLower(false);

    // Check password requirements
    if (password.length < 8) setLacksLength(true);
    else if (!/[!@#$%^&*]/.test(password)) setLacksChar(true);
    else if (!/[A-Z]/.test(password)) setLacksUpper(true);
    else if (!/[0-9]/.test(password)) setLacksNumber(true);
    else if (!/[a-z]/.test(password)) setLacksLower(true);
    else return true;

    return false;
  };

  async function submit(event) {
    event.preventDefault();

    const email = document.getElementById('emailBusiness').value;
    const password = document.getElementById('passwordBusiness').value;
    const confirmedPassword = document.getElementById('passwordConfirmBusiness').value;
    let name = document.getElementById('nameBusiness').value;
    name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();

    let lastName = document.getElementById('lastnameBusiness').value;
    lastName = lastName.charAt(0).toUpperCase() + lastName.slice(1).toLowerCase();

    const account = {
      ownerAccount: true,
      name: name,
      lastname: lastName,
      email: email,
      password: password,
      confirmedPassword: confirmedPassword
    }

    try {
      setShowLoader(true);

      if (signupPassword === confirmPassword) {
        setPasswordsMatch(true);
        const validationRestult = validatePassword(signupPassword);
        console.log('validationRestult: ', validationRestult);
        if (validationRestult) {
          axios.post('http://localhost:8000/createOwnerAccount', account)
            .then(response => {
              const accountId = response.data.insertId;
              setName(account.name);
              setLastName(account.lastname);
              setEmail(account.email);
              setAccountId(accountId)
              setLoggedIn(true);
              navigate('/business/check-email');
            })
            .catch((error) => {
              console.error('Error:', error);
            });
        }

      } else if (signupPassword !== confirmPassword) {
        setPasswordsMatch(false);
      }
      setTimeout(() => setShowLoader(false), 1000);

    } catch (error) {
      console.log(error);
      setShowLoader(false);
    }
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
    setShowConfirmPassword(!showConfirmPassword);
  };

  function redirectGoogleAuth() {
    window.location.href = 'https://accounts.google.com/o/oauth2/v2/auth?client_id=597017456043-lavr17js9qd1dbb8990o56eo8fkhcrou.apps.googleusercontent.com&redirect_uri=http://localhost:8000/createOwnerAccount/google/callback&response_type=code&scope=email%20profile&key=AIzaSyBR6MpxqSo0jcHSgdRMDGY8HK95VkdWXJI'
  }

  return (
    <div className="business-signup-parent">
      <div className="business-signup-child">
        <div className="business-signup-content">
          <div className="business-signup-left-div">
            <img
              src={logoImage}
              alt="logo"
              width={88}
              className="signup-logo"
            />
            <h1 className="business-signup-heading">
              Create Your owner account
            </h1>
            <p className="signup-login-link">
              Already a member?{" "}
              <Link to="/business/login">
                <span className="signup-link"> Login </span>
              </Link>
            </p>
            <div className="or-div">
              <hr className="or-hr" />
              <p className="or-para">OR</p>
              <hr className="or-hr" />
            </div>
            <button className="continue-with-google-div" onClick={redirectGoogleAuth}>
              <img
                src={googleImage}
                width="19px"
                height="19px"
                alt="google"
                className="google-image"
              />

              <p className="continue-with-google">Continue with Google</p>
            </button>
          </div>
          <div className="business-signup-right-div">
            <form className="signupform" onSubmit={submit}>
              <div className="row gap-2">
                <input
                  className="signupinputname col"
                  type="text"
                  placeholder="Name"
                  id="nameBusiness"
                  required
                />
                <input
                  className="signupinputname col"
                  type="text"
                  placeholder="Lastname"
                  id="lastnameBusiness"
                  required
                />
              </div>

              <input
                className="signupinputemail"
                id="emailBusiness"
                type="email"
                placeholder="Email"
                required
              />

              <div
                className="hide-div-signup"
                onClick={togglePasswordVisibility}
              >
                <img src={showPassword ? show : hide} width="20px" height="20px" alt="hide" />
              </div>

              <input
                className="signupinputpass"
                id="passwordBusiness"
                placeholder="Password"
                type={showPassword ? "text" : "password"}
                value={signupPassword}
                onChange={(e) => setsignupPassword(e.target.value)}
                required
              />

              <div className="bullet-parent-div">
                <div className="bullet-group-1">
                  <div className="bullet-div-upper">
                    <img src={bullet} alt="" />
                    <p className={`bullet-para ${lacksLength && 'text-red'}`}>8 or more characters</p>
                  </div>
                  <div className="bullet-div">
                    <img src={bullet} alt="" />
                    <p className={`bullet-para ${lacksChar && 'text-red'}`}>1 special character</p>
                  </div>
                </div>
                <div className="bullet-group-2">
                  <div className="bullet-div-upper">
                    <img src={bullet} alt="" />
                    <p className={`bullet-para ${lacksNumber && 'text-red'}`}>1 number</p>
                  </div>
                  <div className="bullet-div">
                    <img src={bullet} alt="" />

                    <p className={`bullet-para ${lacksUpper && 'text-red'}`}> 1 Uppercase character</p>
                  </div>
                </div>
                <div className="bullet-group-3">
                  <div className="bullet-div">
                    <img src={bullet} alt="" />
                    <p className={`bullet-para ${lacksLower && 'text-red'}`}>1 lowercase character</p>
                  </div>
                  <div className="bullet-div">
                    <p className="bullet-para"></p>
                  </div>
                </div>
              </div>

              <div
                className="hide-div-signup-confirmPass"
                onClick={togglePasswordVisibility}
              >
                <img src={showConfirmPassword ? show : hide} width="20px" height="20px" alt="hide" />
              </div>

              <input
                className="signupconfirminput"
                id="passwordConfirmBusiness"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                placeholder="Confirm Password"
                onChange={(e) => setconfirmPassword(e.target.value)}
                required
              />

              <p className="terms-para">
                By creating an account, you agree to the{" "}
                <span className="terms-span">Terms of use</span> and{" "}
                <span className="terms-span">Privacy Policy.</span>
              </p>

              <Spinner
                text="Create an Account"
                onSubmit={submit}
                loading={showLoader}
                disabled={showLoader}
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

import React from 'react';
import '../../../styling/styles/home-page-styling/outstandingClubs.css';  // ← CSS file for this React component

export default function ClubCard(props) {
    return (
        <div className='outstanded-club'>
            <div className='club-name'>
                <h1>{props.clubName}</h1>
            </div>
            <div className='club-information'>
                <p><i className="bi bi-hourglass" /> {props.closingTime}</p>
                <p><i className="bi bi-badge-wc" /> €{props.toiletCost}</p>
                <p><i class="bi bi-cup-straw" /> {props.drinksCost}</p>
                <p><i class="bi bi-people-fill" style={{ color: props.clubCapacity }} /></p>
            </div>
            <div className='club-image-container'>
                <img src={props.clubImage} alt='Club Image' className='club-image' />
                <div className='image-text'>
                    <p>{props.distance} Km <i className="bi bi-signpost-fill" /></p>
                    <p>{props.paymentMethods} <i className="bi bi-cash" /></p>
                    <p>{props.musicGender} <i className="bi bi-music-note-beamed" /></p>
                </div>
            </div>
        </div>
    )
}
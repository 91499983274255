import React from 'react'
import BusinessUsingFrizda from './BusinessUsingFrizda'
import Features from './Features'
import Footer from './Footer'
import FrizdaHelp from './FrizdaHelp'
import Hero from './Hero'
import HowToFrizda from './HowToFrizda'
import LandingNavbar from './LandingNavbar'
import Testimonials from './Testimonials'

const LandingPage = () => {
    return (
        <div>
            <LandingNavbar />
            <Hero />
            <Features />
            <BusinessUsingFrizda />
            <FrizdaHelp />
            <Testimonials />
            <HowToFrizda />
            <Footer />
        </div>
    )
}

export default LandingPage
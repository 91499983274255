import React, { useRef } from 'react';
import { IoChevronBackCircleOutline, IoChevronForwardCircleOutline } from "react-icons/io5";
import { Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { discoBall, leftDoubleQuotes, userLogo } from '../../assets/images';
import './styles/Testimonials.css';

const Testimonials = () => {

    const SAMPLE_DATA = [
        {
            id: 1,
            feedback: 'Frizda is a game changer for my nightspot. The most varied set of features cover all the needs of a business owners. I recommend Frizda to all nightspot and restaurant owners to make their business stand out and do wonders!!'
        },
        {
            id: 2,
            feedback: 'Frizda transformed our nightspot operations. The comprehensive feature set caters to every need, making it a must-have for any business owner. Highly recommend it for anyone looking to elevate their restaurant or nightspot!'
        },
        {
            id: 3,
            feedback: 'Choosing Frizda for our nightspot was the best decision we made. Its extensive features address every business challenge, making it indispensable for any restaurant or nightspot owner aiming for success.'
        },
        {
            id: 4,
            feedback: 'Frizda has taken our nightspot to the next level. The array of features is unmatched, meeting all our business needs. It\'s an essential tool for any restaurant or nightspot looking to excel!'
        },
        {
            id: 4,
            feedback: 'Frizda\'s powerful feature set has been a game changer for our nightspot. It\'s the ultimate solution for business owners wanting to stand out and achieve remarkable results.'
        }
    ];

    const swiperRef = useRef(null);

    const handlePrevClick = () => {
        if (swiperRef.current) {
            swiperRef.current.slidePrev();
        }
    };

    const handleNextClick = () => {
        if (swiperRef.current) {
            swiperRef.current.slideNext();
        }
    };

    return (
        <div className='testimonials-section position-relative'>
            <div className="testimonials-swiper-wrapper">
                <Swiper
                    // centeredSlides={true}
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    modules={[Navigation]}
                    className="testimonials-swiper"
                    onSwiper={(swiper) => {
                        swiperRef.current = swiper;
                    }}
                >
                    {
                        SAMPLE_DATA.map((data) => (
                            <SwiperSlide>
                                <div className="testimonial-content">
                                    <div className='testimonials-text'>
                                        <p>{data.feedback}</p>
                                    </div>

                                </div>
                            </SwiperSlide>
                        ))
                    }
                </Swiper>
                <div className="testimonial-author">
                    <div className='d-flex align-items-center justify-content-center gap-4'>
                        <img src={userLogo} alt="avatart" />
                        <div className="author-details">
                            <p className='author-name'>Mr. John Petrick </p>
                            <p className='author-designation'>CEO</p>
                        </div>
                    </div>
                    <div className="slider-arrows">
                        <IoChevronBackCircleOutline
                            size={60}
                            className='feedback-swiper-button-prev cursor-pointer'
                            onClick={handlePrevClick}
                        />
                        <IoChevronForwardCircleOutline
                            size={60}
                            className='feedback-swiper-button-next cursor-pointer'
                            onClick={handleNextClick}
                        />
                    </div>
                </div>
            </div>
            <img
                src={leftDoubleQuotes}
                alt="ldqot"
                className='left-double-quotes'
            />
            <img
                src={discoBall}
                alt="Disco"
                className='disco-ball-svg'
            />
        </div>
    )
}

export default Testimonials
import React from 'react';
import '../../styling/styles/home-page-styling/download.css';  // ← CSS for this React component
import AppStore from '../../styling/images/download-buttons/app-store-badge.png';
import PlayStore from '../../styling/images/download-buttons/google-play-badge.png';

function DownloadSection(props) {
    return (
        <div id='download-section'>

            <div className='row mb-3'>
                <div className='col-5 offset-1'>
                    <h1 className='download-title'>{props.downloadText}</h1>
                </div>
            </div>

            <div className='row mt-3'>
                <div className='col-xl-6 col-lg-12 d-flex'>
                    <div className='row flex-lg-column align-items-center d-none d-lg-flex'>
                        <div className='col-6 d-flex justify-content-center'>
                            <img src={AppStore} />
                        </div>
                        <div className='col-6 d-flex justify-content-center'>
                            <img src={PlayStore} />
                        </div>
                    </div>
                </div>
                <div className='col-xl-6 col-lg-12 d-flex justify-content-center align-items-center'>
                </div>
            </div>
        </div>

    )
}

export default DownloadSection;
export const backgroundPaper = require("./design-space-paper-textured-background.png");
export const imagePlaceholder = require("./photo.png");
export const frizdaLogo = require("./Vector.png");
export const userLogo = require("./Frame1618873197.png");
export const getStarted = require("./Forms-rafiki1.png");
export const nightClub = require("./NightClubType.png");
export const bar = require("./NightClubBarType.png");
export const blacklogo = require("./frizdaLogoBlack.png");
export const whiteLogo = require("./frizdaLogoWhite.png");
export const american_express = require("./dashboard/monetics/american-express.webp");
export const visa = require("./dashboard/monetics/visa.webp");
export const master_card = require("./dashboard/monetics/master-card.webp");
export const square = require("./dashboard/monetics/square.webp");
export const stripe = require("./dashboard/monetics/stripe.webp");
export const sumup = require("./dashboard/monetics/sumup.webp");
export const redsys = require("./dashboard/monetics/redsys.webp");
export const ingenico = require("./dashboard/monetics/ingenico.webp");
export const verifone = require("./dashboard/monetics/verifone.webp");
export const zettle = require("./dashboard/monetics/zettle.webp");
export const pageNotAvailable = require("./page-not-available.webp");
export const helpBG = require("./landing-page/help-bg.webp");
export const heroBG = require("./landing-page/hero-img.webp");
export const affilicationSystem = require("./landing-page/affilication-system.webp");
export const clientDataInsights = require("./landing-page/client-data-insights.webp");
export const frizdaAds = require("./landing-page/frizda-ads.webp");
export const frizdaSmartMap = require("./landing-page/frizda-smart-map.webp");
export const monetoryStatistics = require("./landing-page/monetory-statistics.webp");
export const ticketSelling = require("./landing-page/ticket-selling.webp");
export const allBarOne = require("./landing-page/business-partners/all-bar-one.webp");
export const amnesia = require("./landing-page/business-partners/amnesia.webp");
export const belushi = require("./landing-page/business-partners/belushi.webp");
export const brewdog = require("./landing-page/business-partners/brewdog.webp");
export const olearys = require("./landing-page/business-partners/olearys.webp");
export const opium = require("./landing-page/business-partners/opium.webp");
export const fabrik = require("./landing-page/business-partners/fabrik.webp");
export const step1 = require("./landing-page/help-steps/step-1.webp");
export const step2 = require("./landing-page/help-steps/step-2.webp");
export const step3 = require("./landing-page/help-steps/step-3.webp");
export const step4 = require("./landing-page/help-steps/step-4.webp");
export const stepCounts = require("./landing-page/help-steps/step-counts.webp");
export const responsiveStepCounts = require("./landing-page/help-steps/responsive-step-counts.webp");
export const leftDoubleQuotes = require("./landing-page/left-double-quotes.webp");
export const discoBall = require("./landing-page/disco-ball.webp");
export const calendly = require("./landing-page/calendly-logo.webp");

// Tickets images
export const ticketDanceClub = require("./dashboard/tickets/dance-club.webp");
export const ticketDiscoBall = require("./dashboard/tickets/disco-ball.webp");
export const ticketNightClub = require("./dashboard/tickets/night-club.webp");
export const ticketDanceClubOption = require("./dashboard/tickets/dance-club-option.webp");
export const ticketDiscoBallOption = require("./dashboard/tickets/disco-ball-option.webp");
export const ticketNightClubOption = require("./dashboard/tickets/night-club-option.webp");